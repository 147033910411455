import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgStrapiAuthConfig } from './strapi/auth/ng-strapi-auth-config';

@Injectable({ providedIn: 'root' })
export class ApiCallsService {
  constructor(
    private httpClient: HttpClient,
    @Inject('config') private config: NgStrapiAuthConfig
  ) {}

  async fetchAreas() {
    try {
      const results: any = await this.httpClient
        .get(this.config.apiUrl + '/areas', {})
        .toPromise();
      console.log('fetchAreas result', results);
      // this.availableAreas = [...results];
      return results;
    } catch (e) {
      console.error('Error fetching Areas', e);
      return [];
    }
  }

  async createArea(area) {
    try {
      await this.httpClient
        .post(this.config.apiUrl + '/areas', {
          data: { ...area },
        })
        .toPromise();
      return 'ok';
    } catch (e) {
      console.error('Error creating a new area', e);
      return 'error';
    }
  }

  async editArea(area) {
    const { id, name, description, type } = area;
    try {
      await this.httpClient
        .put(this.config.apiUrl + `/areas/${id}`, {
          data: {
            name,
            description: description || undefined,
            type: type || undefined,
          },
        })
        .toPromise();
      return 'ok';
    } catch (e) {
      console.error('Error updating area', e);
      return 'error';
    }
  }

  async deleteArea(areaId) {
    try {
      await this.httpClient
        .delete(this.config.apiUrl + `/areas/${areaId}`, {})
        .toPromise();
      return 'ok';
    } catch (e) {
      console.error('Error deleting area', e);
      return e;
    }
  }
}
