<ng-template #LoadingSpinner>
  <mat-card fxFlex class="pegasus-card" fxLayoutAlign="center center">
    <mat-card-content>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-spinner [diameter]="50" [strokeWidth]="8"></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<mat-tab-group id="settings-tabs">
  <!-- MY PROFILE SETTINGS -->
  <mat-tab label="My Profile">
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxFlex *ngIf="!loaded">
        <ng-template [ngTemplateOutlet]="LoadingSpinner"></ng-template>
      </div>

      <div fxLayout="row wrap" style="width: 100%" *ngIf="loaded">
        <app-my-profile-settings
          style="display: block; width: 100%"
          [userStatus]="userStatus"
        ></app-my-profile-settings>
      </div>
    </div>
  </mat-tab>

  <!-- USERS SETTINGS -->
  <mat-tab label="Users" *ngIf="isAdminUser">
    <div fxLayout="row wrap" fxLayoutAlign="stretch">
      <div fxFlex *ngIf="!loaded">
        <ng-template [ngTemplateOutlet]="LoadingSpinner"></ng-template>
      </div>

      <div fxLayout="row wrap" style="width: 100%" *ngIf="loaded">
        <app-users-settings
          style="display: block; width: 100%"
          [fetchUsers]="fetchUsers"
          [users]="users"
          [brandAreas]="brandAreas"
          [brandVenues]="brandVenues"
          [brandAreasRaw]="brandAreasRaw"
          [brandVenuesRaw]="brandVenuesRaw"
        ></app-users-settings>
      </div>
    </div>
  </mat-tab>

  <!-- VENUES SETTINGS -->
  <mat-tab label="Venues" *ngIf="isAdminUser">
    <div fxLayout="row wrap" fxLayoutAlign="stretch">
      <div fxFlex *ngIf="!loaded">
        <ng-template [ngTemplateOutlet]="LoadingSpinner"></ng-template>
      </div>

      <div fxLayout="row wrap" style="width: 100%" *ngIf="loaded">
        <app-venue-settings
          style="display: block; width: 100%"
          [venues]="brandVenuesRaw"
          (refetchBrandSettings)="refetchBrandSettings()"
        ></app-venue-settings>
      </div>
    </div>
  </mat-tab>

  <!-- AREA SETTINGS -->
  <mat-tab label="Areas" *ngIf="isAdminUser">
    <div fxLayout="row wrap" fxLayoutAlign="stretch">
      <div fxFlex *ngIf="!loaded">
        <ng-template [ngTemplateOutlet]="LoadingSpinner"></ng-template>
      </div>

      <div fxLayout="row wrap" style="width: 100%" *ngIf="loaded">
        <app-area-settings
          style="width: 100%"
          [users]="users"
          [loggedInUser]="userStatus"
          (updateUsers)="fetchUsers()"
          (refetchBrandSettings)="refetchBrandSettings()"
        ></app-area-settings>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
