import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DateRange,
  StatisticsParams,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { RestaurantsService } from '../../../../services/restaurants.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-trends-view',
  templateUrl: './trends-view.component.html',
  styleUrls: ['./trends-view.component.scss'],
})
export class TrendsViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() weekDaysSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public dateRange: DateRange;
  public weekDaysSelected: string[] = [];
  public weekDaysSelectedOld: string[] = [];
  public allVenues: Venue[] = [];
  public trendsGroup: Venue[] = [];
  public weekDaysChips: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  public showWeekDaysChips = true;
  public isMobile = false;

  constructor(private restaurantsService: RestaurantsService) {}

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }

  selectionTrendsGroup($event) {
    console.log('mat-select', $event.value, 'trendsGroup');
    this.trendsGroup = [...$event.value];
    console.log('trendsGroup', this.trendsGroup);
  }

  applyWeekDaysFilter(event) {
    event.stopPropagation();

    this.weekDaysSelectedChange.emit({
      ...this.statisticsParams,
      weekDaysSelected: this.weekDaysSelected,
    });
  }

  checkWeekDaySelection(weekDay: string) {
    if (weekDay !== undefined) {
      return this.weekDaysSelected.includes(
          String(moment().day(weekDay).format('e'))
      );
    } else {
      return false;
    }
  }

  changeWeekDayChip(weekDay: string) {
    const weekDayIndex = String(moment().day(weekDay).format('e'));
    let newWeekDaysSelected = [];

    if (this.weekDaysSelected.includes(weekDayIndex)) {
      newWeekDaysSelected = this.weekDaysSelected.filter(
          (e) => e !== weekDayIndex
      );
    } else {
      newWeekDaysSelected = [...this.weekDaysSelected, weekDayIndex];
    }

    // Make sure there is always one day selected as minimum
    if (newWeekDaysSelected.length === 1) {
      this.weekDaysSelectedOld = [...newWeekDaysSelected];
    }
    if (newWeekDaysSelected.length === 0) {
      newWeekDaysSelected = [...this.weekDaysSelectedOld];
      // return;
    }

    this.weekDaysSelected = [...newWeekDaysSelected];
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams } = change;
    console.log('statisticsParams', statisticsParams);
    this.weekDaysSelected = [...this.statisticsParams.weekDaysSelected];
    this.allVenues = [...this.restaurantsService.allVenuesData];

    if (statisticsParams && statisticsParams.firstChange) {
      this.trendsGroup.push(this.venueData);
    }

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      const start = statisticsParams.currentValue.periodStartDate.clone();
      const end = statisticsParams.currentValue.periodEndDate.clone();

      this.dateRange = {
        start: start.format('DD/MM/YYYY'),
        end: end.format('DD/MM/YYYY'),
      };
    }
  }
}
