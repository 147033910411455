<ng-container *ngIf="withCard">
  <div
    class="flippable-card"
    fxFlexAlign="start"
    fxFlex="100"
    fxFlexOrder="0"
    fxFlex.lt-xs="0 1 calc(100%)"
    fxFlex.gt-xs="100"
    style="max-width: calc(100%)"
  >
    <mat-card class="pegasus-card front">
      <mat-card-content class="">
        <p>Default values for the charts, used to color charts.</p>
        <p>
          Example: Total duration is set to 600 seconds -> Chart color with
          duration under 600 will be green, under 700 will be yellow, over 700
          will be red.
        </p>
        <p>
          To change individual station durations please contact Mikhail at
          <a href="mailto:mikhail@eyezill.ai">mikhail@eyezill.ai</a>
        </p>
        <div fxLayout="column" class="table-card-holder">
          <!--				<button mat-raised-button color="primary" style="width: fit-content;" (click)="inviteUser()"><mat-icon>send</mat-icon> Invite users</button>-->
          <ng-template *ngIf="withCard; then table"></ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!withCard">
  <ng-template *ngIf="!withCard; then table"></ng-template>
</ng-container>

<ng-template #table>
  <table mat-table [dataSource]="venues" class="mat-elevation-z0">
    <!-- Note that these columns can be defined in any order.
			   The actual rendered columns are set as a property on the row definition"
         ['name', 'email', 'active', 'venueAccess'] -->

    <!-- Name Column -->
    <ng-container matColumnDef="name" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field class="settings-field">
          <input
            matInput="number"
            value="{{ element.name }}"
            disabled="true"
            style="color: black"
          />
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Area Column -->
    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef>Area</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="" class="settings-field">
          <mat-select
            name="area"
            (selectionChange)="changeVenueArea($event, element.id)"
            [value]="element.area?.id.toString() || 'none'"
          >
            <mat-option [value]="'none'" disabled> - Select Area - </mat-option>
            <mat-option
              *ngFor="let area of availableAreas"
              value="{{ area.id.toString() }}"
              >{{ area.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- PaceSetter Column -->
    <ng-container matColumnDef="pacesetter">
      <th mat-header-cell *matHeaderCellDef>Pacesetter</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="" class="settings-field">
          <mat-select
            name="venue"
            (selectionChange)="changeVenuePacesetter($event, element.id)"
            [value]="element.pacesetter_venue?.id"
          >
            <ng-container *ngFor="let venue of venues">
              <mat-option *ngIf="element.id !== venue.id" [value]="venue.id">
                {{ venue.name }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="" class="settings-field">
          <mat-select
            name="type"
            (selectionChange)="changeVenueType($event, element.id)"
            [value]="element?.type || 'none'"
          >
            <mat-option [value]="'none'" disabled> - Select Type - </mat-option>
            <mat-option *ngFor="let type of venueTypes" value="{{ type }}">{{
              type.replace('_', ' ')
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Dynamic Columns -->
    <ng-container
      *ngFor="let columnName of dynamicColumns"
      [matColumnDef]="columnName"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [matTooltip]="columnNamesFromKey[columnName]?.description"
        [matTooltipClass]="'setting-tooltip'"
        [matTooltipPosition]="'above'"
      >
        {{ columnNamesFromKey[columnName]?.name }}
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field class="settings-field">
          <input
            matInput="number"
            type="number"
            [(ngModel)]="element.tableValues[columnName]"
            [disabled]="
              changeDisabled || element.tableValues[columnName + 'Disabled']
            "
          />
          <mat-hint align="start">{{
            element.tableValues[columnName + 'DisplayUnit']
          }}</mat-hint>
        </mat-form-field>
      </td></ng-container
    >

    <!-- Actions Column -->
    <ng-container matColumnDef="actions" [stickyEnd]="true" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          color="primary"
          aria-label="Apply changes"
          (click)="applyVenueChanges(element)"
          [disabled]="!hasValueChanged(element)"
        >
          <mat-icon>save</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-template>
