<mat-card class="pegasus-card mat-card-top-panel-override">
  <mat-expansion-panel
    id="header-expanel"
    class="pegasus-card mat-elevation-z0"
  >
    <mat-expansion-panel-header style="height: fit-content">
      <div
        fxFlex
        fxLayout="row wrap"
        fxLayoutAlign="space-between center no-wrap"
        style="
          width: 100%;
          height: fit-content;
          flex-wrap: nowrap;
          padding: 10px 0;
        "
      >
        <div fxLayoutAlign="start center no-wrap">
          <span>
            Date
            <strong id="header-date">{{ date || 'Not defined' }}</strong>
          </span>

          <div (click)="$event.stopPropagation()">
            <button
              id="edit-date"
              mat-icon-button
              style="margin-left: 10px"
              aria-label="Date configuration"
              alt="openSidebar"
              (click)="openRightSidebar()"
            >
              <img
                src="assets/images/pegasus-icons/Edit.svg"
                alt="openSidebar"
                class="settings-cog heading-color"
              />
            </button>
          </div>
        </div>

        <!-- TIMESLOT CHIPS -->
        <div fxLayoutAlign="center center">
          <mat-chip-list
            aria-label="Weekday selection"
            [multiple]="true"
            style="margin-right: 20px"
          >
            <ng-container *ngFor="let slot of timeslots">
              <mat-chip
                id="{{ slot.title }}-chip"
                fxLayoutAlign="center center"
                style="cursor: pointer"
                [selected]="checkTimeslotsSelection(slot.title)"
                (click)="timeslotChange(slot.title)"
              >
                {{ slot.title }}
                <span
                  style="margin-left: 10px; margin-top: 2px; font-size: x-small"
                >
                  {{ slot.interval }}
                </span>
              </mat-chip>
            </ng-container>
          </mat-chip-list>
        </div>
      </div>
    </mat-expansion-panel-header>

    <app-venue-settings
      data-testid="app-venue-settings"
      [venues]="[venueData]"
      [withCard]="false"
      [hideStaticColumns]="true"
      [changeDisabled]="true"
      style="display: block; width: 100%; overflow: auto"
    ></app-venue-settings>
  </mat-expansion-panel>
</mat-card>

<!-- Used for E2E daily-insights testing purposes only -->
<div id="timeslots-selected" style="display: none">
  {{ timeslotsSelected.join(',') }}
</div>

<!-- GRAFANA VIEW -->
<app-grafana-charts-view
  data-testid="app-grafana-charts-view"
  [venueData]="venueData"
  [statisticsParams]="statisticsParams"
></app-grafana-charts-view>
