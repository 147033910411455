<div class="login-register-box">
  <mat-card>
    <mat-card-content *ngIf="!success">
      <a
        [routerLink]="['/authentication/login']"
        class="link"
        data-testid="back_to_login"
      >
        <mat-icon>arrow_back</mat-icon>
      </a>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <img
            alt="homepage"
            src="assets/images/0.5x/full_color@0.5x.png"
            class="large-logo"
          />
          <h4 class="m-t-0">Forgot password</h4>
        </div>

        <!-- ERROR MESSAGE -->
        <div
          *ngIf="msg"
          class="bg-danger p-10 text-white m-b-15"
          data-testid="error_message"
        >
          {{ msg }}
        </div>

        <p class="text-center font-14">
          Please enter your email and we will send you a link to restore your
          password.
        </p>

        <div fxLayout="column" fxLayoutAlign="space-around">
          <div class="pb-1">
            <mat-form-field style="width: 100%">
              <input
                matInput
                placeholder="Your email"
                type="email"
                data-testid="email"
                [formControl]="form.controls['email']"
              />
            </mat-form-field>
            <!-- <small
              *ngIf="
                form.controls['email'].hasError('required') &&
                form.controls['email'].touched
              "
              class="text-danger support-text"
              data-testid="error_message"
              >Please enter a valid email.</small
            >
            <small
              *ngIf="
                form.controls['email'].errors?.email &&
                form.controls['email'].touched
              "
              class="text-danger support-text"
              data-testid="error_message"
              >Please enter a valid email.</small
            > -->
          </div>
          <button
            *ngIf="!loading"
            mat-raised-button
            color="primary"
            type="submit"
            data-testid="reset_password_btn"
            class="btn-block btn-lg m-t-20"
            [disabled]="!form.valid"
          >
            Restore password
          </button>
          <button
            *ngIf="loading"
            mat-raised-button
            color="primary"
            class="btn-block btn-lg m-t-20"
            [disabled]="!form.valid"
          >
            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
          </button>
        </div>
      </form>
    </mat-card-content>

    <mat-card-content *ngIf="success">
      <div class="text-center">
        <img
          alt="homepage"
          src="assets/images/0.5x/full_color@0.5x.png"
          class="large-logo"
        />
        <h4 class="m-t-0">We have sent you a link to restore your password!</h4>
      </div>
      <p class="text-center font-14" data-testid="check_email_message">
        Please check your email.
      </p>

      <div fxLayout="column" fxLayoutAlign="space-around"></div>
    </mat-card-content>
  </mat-card>
</div>
