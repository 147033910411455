<mat-card class="pegasus-card mat-card-top-panel-override">
  <mat-expansion-panel
    id="header-expanel"
    class="pegasus-card mat-elevation-z0"
  >
    <mat-expansion-panel-header style="height: fit-content">
      <div
        fxFlex
        fxLayout="row wrap"
        fxLayoutAlign="space-between center no-wrap"
        style="
          width: 100%;
          height: fit-content;
          flex-wrap: nowrap;
          padding: 10px 0;
        "
      >
        <div fxLayoutAlign="start center no-wrap">
          Date from
          <strong id="header-date-start" style="margin: 0 5px">
            {{ dateRange.start || 'Not defined' }}
          </strong>
          to
          <strong id="header-date-end" style="margin: 0 5px">
            {{ dateRange.end || 'Not defined' }}
          </strong>

          <!-- Open calendar rightsidebar button -->
          <div (click)="$event.stopPropagation()">
            <button
              id="edit-date"
              mat-icon-button
              style="margin-left: 10px"
              aria-label="Date configuration"
              alt="openSidebar"
              (click)="openRightSidebar()"
            >
              <img
                src="assets/images/pegasus-icons/Edit.svg"
                alt="openSidebar"
                class="settings-cog heading-color"
              />
            </button>
          </div>
        </div>

        <!-- Weekdays chips -->
        <div
          id="weekdays-chips-container"
          fxLayout="row"
          fxLayoutAlign="flex-end center"
          *ngIf="showWeekDaysChips && !isMobile"
          (click)="$event.stopPropagation()"
        >
          <button
            id="apply-weekdays-filter"
            mat-raised-button
            style="background-color: #3949ab; color: white; margin-right: 20px"
            (click)="applyWeekDaysFilter($event)"
            alt="applyWeekdayFilter"
            *ngIf="
              weekDaysSelected.sort().join() !==
              statisticsParams.weekDaysSelected.sort().join()
            "
          >
            Apply
          </button>

          <mat-chip-list
            id="weekdays-chips"
            aria-label="Weekday selection"
            [multiple]="true"
            style="margin-right: 20px"
          >
            <ng-container *ngFor="let weekday of weekDaysChips">
              <mat-chip
                style="cursor: pointer"
                [selected]="checkWeekDaySelection(weekday)"
                (click)="changeWeekDayChip(weekday)"
                >{{ weekday }}</mat-chip
              >
            </ng-container>
          </mat-chip-list>
        </div>
      </div>
    </mat-expansion-panel-header>

    <!-- Venue Settings table -->
    <app-venue-settings
      [venues]="[venueData]"
      [withCard]="false"
      [hideStaticColumns]="true"
      [changeDisabled]="true"
      style="display: block; width: 100%; overflow: auto"
    ></app-venue-settings>
  </mat-expansion-panel>
</mat-card>

<!-- Used for E2E testing purposes only -->
<div id="timeslots-selected" style="display: none">
  {{ statisticsParams.timeslotsSelected.join(',') }}
</div>

<!-- GRAFANA VIEW -->
<app-grafana-charts-view
  data-testid="app-grafana-charts-view"
  [venueData]="venueData"
  [statisticsParams]="statisticsParams"
></app-grafana-charts-view>
