import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { StatisticsParams, Venue } from '../../../../utils/CommonInterfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-daily-view',
  templateUrl: './daily-view.component.html',
  styleUrls: ['./daily-view.component.scss'],
})
export class DailyViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() timeslotsSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public date: string;
  public timeslotsSelected: string[] = [];
  public timeslotsSelectedOld: string[] = [];
  public timeslots: {}[] = [];

  constructor(private router: Router) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.DailyViewComponent = this;
    }
  }

  openWeeklyView() {
    this.router.navigate(['/statistics-weekly']);
  }

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }

  checkTimeslotsSelection(slot: string) {
    return this.timeslotsSelected.includes(slot);
  }

  timeslotChange(slot) {
    let newTimeslots = [];

    if (this.timeslotsSelected.includes(slot)) {
      newTimeslots = this.timeslotsSelected.filter((item) => item !== slot);
    } else {
      newTimeslots = [...this.timeslotsSelected, slot];
    }
    this.timeslotsSelected = [...newTimeslots];

    // Make sure there is always one day selected as minimum
    if (this.timeslotsSelected.length === 1) {
      this.timeslotsSelectedOld = [...this.timeslotsSelected];
    }
    if (this.timeslotsSelected.length === 0) {
      this.timeslotsSelected = [...this.timeslotsSelectedOld];
      return;
    }

    this.timeslotsSelectedChange.emit({
      ...this.statisticsParams,
      timeslotsSelected: this.timeslotsSelected,
    });
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams, venueData } = change;

    if (venueData && venueData.firstChange) {
      this.timeslots = Object.entries(venueData.currentValue.timeslots).map(
        ([k, v]) => {
          return {
            title: k,
            interval: `${v[0][0]}:${v[0][1]} - ${v[1][0]}:${v[1][1]}`,
          };
        }
      );
    }

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      this.date =
        statisticsParams.currentValue.selectedDate.format('DD/MM/YYYY');
      this.timeslotsSelected = this.statisticsParams.timeslotsSelected;
    }
  }
}
