<mat-card class="pegasus-card mat-card-top-panel-override">
  <div
    fxFlex
    fxLayout="row wrap"
    fxLayoutAlign="space-between center no-wrap"
    style="
      width: 100%;
      height: fit-content;
      flex-wrap: nowrap;
      padding: 10px 24px;
    "
  >
    <div
      fxFlex
      fxLayoutAlign="space-between center no-wrap"
      style="font-size: 15px; font-weight: 400"
    >
      <span style="white-space: nowrap"> Date from </span>
      <strong id="header-date-start" style="margin: 0 5px">
        {{ dateRange.start || 'Not defined' }}
      </strong>
      to
      <strong id="header-date-end" style="margin: 0 5px">
        {{ dateRange.end || 'Not defined' }}
      </strong>

      <!-- Open calendar rightsidebar button -->
      <button
        id="edit-date"
        mat-icon-button
        style="margin-left: 10px"
        aria-label="Date configuration"
        alt="openSidebar"
        (click)="openRightSidebar()"
      >
        <img
          src="assets/images/pegasus-icons/Edit.svg"
          alt="openSidebar"
          class="settings-cog heading-color"
        />
      </button>

      <div
        fxFlex
        fxLayoutAlign="space-between center"
        style="margin-left: 20px"
      >
        <!-- Venue List A multiple select -->
        <!-- TODO: implement different colour when a selection is made in the other group -->
        <mat-form-field>
          <mat-label>Venues Group A</mat-label>
          <mat-select
            [value]="[venueData]"
            (selectionChange)="selectionGroupA($event)"
            multiple
          >
            <ng-container *ngFor="let venue of allVenues">
              <mat-option
                [ngStyle]="{
                  'background-color': checkSelected(venue, venuesGroupB)
                    ? '#defedf'
                    : 'white'
                }"
                [value]="venue"
              ><span>{{ venue.name }}</span> <span *ngIf="venue.area"> - {{venue.area?.name}}</span></mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>

        <!-- Venue List B multiple select -->
        <mat-form-field style="margin-left: 20px">
          <mat-label>Venues Group B</mat-label>
          <mat-select
            [value]="[selectedVenuePaceSetter]"
            (selectionChange)="selectionGroupB($event)"
            multiple
          >
            <ng-container *ngFor="let venue of allVenues">
              <mat-option
                [ngStyle]="{
                  'background-color': checkSelected(venue, venuesGroupA)
                    ? '#defefd'
                    : 'white'
                }"
                [value]="venue"
              ><span>{{ venue.name }}</span> <span *ngIf="venue.area"> - {{venue.area?.name}}</span></mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-card>

<!-- Used for E2E testing purposes only -->
<div id="timeslots-selected" style="display: none">
  {{ statisticsParams.timeslotsSelected.join(',') }}
</div>

<!-- GRAFANA VIEW -->
<app-grafana-charts-view
  data-testid="app-grafana-charts-view"
  [venueData]="venueData"
  [statisticsParams]="statisticsParams"
  [crossGroupA]="venuesGroupA"
  [crossGroupB]="venuesGroupB"
></app-grafana-charts-view>
