<!-- <h3>Please confirm</h3> -->
<div mat-dialog-content class="flexbox-parent" style="color: '#e91e63'">
  <p style="text-align: center">
    <mat-icon style="transform: scale(2)">error_outline</mat-icon>
  </p>
  <p><strong> {{data.name}}</strong> will be deleted, are you sure?</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-dialog-close="cancel">Cancel</button>

  <button
    data-testid="submit-button"
    mat-button
    color="warn"
    mat-dialog-close
    [mat-dialog-close]="data.formValues"
  >
    Yes, delete
  </button>
</div>
