import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-competition-embedded',
  templateUrl: './competition-embedded.component.html',
  styleUrls: ['./competition-embedded.component.scss']
})
export class CompetitionEmbeddedComponent implements AfterViewInit {
    private resizeObserver: ResizeObserver;
    private mutationObserver: MutationObserver;
    private scrollInterval: number;
    private parentElm: HTMLElement;
    private childElm: HTMLElement;
    private scrollDirection: 'up' | 'down' = 'down';

    @ViewChild('autoScroll') autoScrollElm: ElementRef<HTMLInputElement>;

    _competitionData = {};
  displayedColumns = ['position', 'name', 'score'];
  dataSource = [];
    playOffVenues = [];
    playOutVenues = [];
    toWinVenues = [];
    notToLoseVenues = [];
    private resetScroll = false;

    private previousDisplayCompetition = false;
  @Input() set displayCompetition(displayCompetition) {
      if (displayCompetition && this.previousDisplayCompetition !== displayCompetition) {
          this.resetScroll = true;
      }
      this.previousDisplayCompetition = displayCompetition;
  }

  @Input() competitionAutoScrollSpeed = 2500;

  @Input() set competitionData(data) {
    console.log('data', data);
    if (!data) { return; }
    console.log('competitionData', data);
    if (!environment.production) {

        data.grafanaData['Leaderboards top'] = [{
            'metric': 'Waterloo',
            'max': '189'
        },
            {
                'metric': 'Reading',
                'max': '181'
            },
            {
                'metric': 'Brent Cross',
                'max': '177'
            },
            {
                'metric': 'Argyll Street',
                'max': '173'
            },
            {
                'metric': 'Canary Wharf',
                'max': '164'
            },
            {
                'metric': 'High Street Kensington',
                'max': '164'
            },
            {
                'metric': 'St Christopher\'s Place',
                'max': '153'
            },
            {
                'metric': 'Liverpool Street',
                'max': '133'
            },
            {
                'metric': 'The O2',
                'max': '126'
            },
            {
                'metric': 'Strand',
                'max': '126'
            },
            {
                'metric': 'Bromley',
                'max': '117'
            }];
    }

      this.dataSource = data.grafanaData['Leaderboards top'].map((item, index) => {
          return {
              position: index + 1,
              name: item.metric,
              score: item.max,
          };
      });

    // section for second week competition venues
    //   const week2Groups = this.splitVenueGroups(this.dataSource, [
    //       {
    //           name: 'Play Off',
    //           venues: ['Waterloo', 'Argyll Street', 'Reading', 'Canary Wharf', 'St Christopher\'s Place', 'Strand']
    //       },
    //       {
    //           name: 'Play Out',
    //           venues: ['Brent Cross', 'High Street Kensington', 'Liverpool Street', 'The O2', 'Bromley']
    //       }
    //   ]);
    //   this.playOffVenues = week2Groups['Play Off'];
    //     this.playOutVenues = week2Groups['Play Out'];
    // section for third week competition venues
      const week3Groups = this.splitVenueGroups(this.dataSource, [
          {
              name: 'Play Off',
              venues: ['Waterloo', 'Reading', 'Argyll Street', 'Brent Cross', 'High Street Kensington']
          },
          {
              name: 'Play Out',
              venues: ['Canary Wharf', 'St Christopher\'s Place', 'Strand',  'Liverpool Street', 'The O2', 'Bromley']
          }
      ]);
      this.playOffVenues = week3Groups['Play Off'];
      this.playOutVenues = week3Groups['Play Out'];


    this._competitionData = data;
  }
  @Input () loadingData = true;
  @Input () autoScroll = false;
  @Input () isStandalone = false;

  constructor() {

  }

    splitVenueGroups(venues, splitGroups: [{ name: string; venues: string[] }, { name: string; venues: string[] }]) {
      console.log('venues', venues);
        const splits = {};
      splitGroups.forEach((group) => {
          splits[group.name] = venues.filter((venue) => true);
          splits[group.name].sort((a, b) => {
              return b.score - a.score;
          });
          splits[group.name] = splits[group.name].map((item, index) => {
              return {
                  position: index + 1,
                  name: item.name,
                  score: item.score,
              };
          });
      });
      console.log('splits', splits);
        return splits;
  }
    performScroll() {
        if (this.parentElm && this.childElm) {
            if (this.resetScroll) {
                console.log('reseting scroll');
                this.scrollDirection = 'down';
                this.parentElm.scrollTop = 0;
                this.resetScroll = false;
            } else {
                if (this.scrollDirection === 'down') {

                    if (this.parentElm.scrollTop + 5 > this.parentElm.scrollHeight - this.parentElm.clientHeight) {
                        this.scrollDirection = 'up';
                    }
                    this.parentElm.scrollTop += 300;
                }
                if (this.scrollDirection === 'up') {

                    if (this.parentElm.scrollTop <= 0) {
                        this.scrollDirection = 'down';
                    }
                    this.parentElm.scrollTop -= 300;
                }

            }
        }
        setTimeout(() => {
            this.performScroll();
        }, this.competitionAutoScrollSpeed);
    }
  ngAfterViewInit(): void {
      if (this.isStandalone) {
          this.observeHeightChanges();
          this.scrollDiv();
          this.performScroll();


          // this.scrollInterval = setInterval(() => {
          //     // console.log('diff', this.parentElm.scrollHeight - this.parentElm.offsetHeight);
          //     // console.log('scroll', this.parentElm.scrollTop );
          //
          // }, 10);
      }
  }

    private observeHeightChanges() {
        const contentElement = this.autoScrollElm.nativeElement;
        const scrollContainer = contentElement.parentElement;

        // this.resizeObserver = new ResizeObserver(() => {
        //     this.scrollDiv();
        // });
        //
        // this.resizeObserver.observe(scrollContainer);

        this.mutationObserver = new MutationObserver(() => {
            this.scrollDiv();
        });

        this.mutationObserver.observe(contentElement, { childList: true, subtree: true });
    }

    private scrollDiv() {
        const contentElement = this.autoScrollElm.nativeElement;
        const tableElement = contentElement.querySelector('table');
        // console.log('tableElement', tableElement);
        if (tableElement) {
            this.parentElm = contentElement;
            this.childElm = tableElement;
        }
    }



}
