import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgStrapiAuthService } from '../services/strapi/auth/ng-strapi-auth.service';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // providers: [MyserviceService]
})
export class LoginComponent implements OnInit {
  public errorMessage = '';
  public emailConfirmed = false;
  public loginHappening = false;
  public registeredDifferently = false;
  showRedirectMessage: boolean;

  constructor(
    private authService: NgStrapiAuthService,
    private routes: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Fired when the user clicks the Sign in button
   *
   * @param uname user name
   * @param p user password
   * @returns
   */
  async check(uname: string, p: string) {
    this.loginHappening = true;

    if (!uname || !p) {
      this.loginHappening = false;
      return (this.errorMessage = 'Please enter your email and password');
    }

    try {
      const user = await this.authService.signIn(uname, p);

      if (user.role.type === 'live_venue_viewer') {
        this.routes.navigate(['live-view']);
      } else {
        this.routes.navigate(['statistics-weekly']);
      }

    } catch (e) {
      console.log('e', e);
      const foundError = e;
      this.loginHappening = false;
      if (foundError === 'Identifier or password invalid.') {
        return (this.errorMessage = 'Email or password incorrect');
      }
      this.errorMessage = foundError;
    }

    this.loginHappening = false;
  }

  async ngOnInit() {
    try {
      const status = this.authService.autoSignIn();
      console.log('status', status);
      this.routes.navigate(['dashboard']);
    } catch (e) {
      console.log('e', e);
    }
    this.emailConfirmed = Boolean(
      this.route.snapshot.queryParamMap.get('emailConfirm')
    );
    this.registeredDifferently = Boolean(
      this.route.snapshot.queryParamMap.get('registeredDifferently')
    );
    this.showRedirectMessage = this.route.snapshot.queryParamMap.has('redirect');
    // console.log('other param', );
  }

  openExternal(link) {
    this.document.location.href = link;
  }
}
