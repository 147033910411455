<div fxLayout="column" style="padding: 0px 16px 16px 16px">
  <!-- DAY -->
  <div
    appearance="standard"
    *ngIf="displayFields.indexOf('singleDate') > -1"
    fxFlex="100"
  >
    <mat-calendar
      id="calendar"
      [startAt]="date"
      [selected]="date"
      (selectedChange)="dateChanged($event)"
    ></mat-calendar>
  </div>

  <!-- WEEK SELECT -->
  <mat-form-field
    id="week-select"
    appearance="standard"
    fxFlex="100"
    *ngIf="displayFields.indexOf('weekSelect') > -1"
  >
    <mat-label> Week </mat-label>
    <mat-select
      [(value)]="primaryWeek"
      (selectionChange)="weekChange()"
      (openedChange)="weekOpened()"
    >
      <ng-container *ngFor="let entry of primaryWeekValues">
        <mat-option
          id="{{ entry.value }}"
          value="{{ entry.value }}"
          (click)="selectWeekType(entry.value)"
          >{{
            !displayFields.indexOf('trends') && entry.value === 'specific_week'
              ? 'Date range'
              : entry.name
          }}</mat-option
        >
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div
    style="margin-bottom: 30px"
    *ngIf="
      displayFields.indexOf('weekdaySelector') > -1 &&
      primaryWeek === 'specific_week'
    "
  >
    {{ specificWeekStartToEndDays() }}
  </div>

  <!-- WEEK DAYS SELECTOR -->
  <mat-form-field
    id="weekdays-select"
    appearance="standard"
    fxFlex="100"
    *ngIf="displayFields.indexOf('weekdaySelector') > -1"
  >
    <mat-label>Week days</mat-label>
    <mat-select
      [(value)]="weekDaysSelected"
      (selectionChange)="weekDaysChange()"
      multiple
    >
      <ng-container *ngFor="let entry of weekDaysSelectorValues">
        <mat-option value="{{ entry.value }}">{{ entry.name }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- TIMESLOTS SELECTOR -->
  <mat-form-field
    id="timeslots-select"
    appearance="standard"
    fxFlex="100"
    *ngIf="displayFields.indexOf('timeslotSelector') > -1"
  >
    <mat-label>Timeslots</mat-label>
    <mat-select
      [(value)]="timeslotsSelected"
      (selectionChange)="timeslotsChange()"
      multiple
    >
      <ng-container *ngFor="let entry of timeslotSelectorValues">
        <mat-option id="{{ entry.value }}" value="{{ entry.value }}">{{
          entry.value
        }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- GROUP TYPE SELECTOR -->



    <div
            id="groupby-select"
            fxFlex="100"
            fxLayout="column"
            *ngIf="displayFields.indexOf('groupbySelector') > -1">
      <mat-label  appearance="standard"
      >Group By</mat-label>

      <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="groupBySelected"
              (change)="groupByChange()"
      >
        <ng-container *ngFor="let entry of groupBySelectorValues">

          <mat-radio-button
                  class="vertical-radio-button"
                  id="{{ entry.value }}" value="{{ entry.value }}"
          >
            {{ entry.name }}
          </mat-radio-button>
        </ng-container>


      </mat-radio-group>
    </div>




  <!-- APPLY BUTTON FOR DAILY CALENDAR, WEEK DAYS SELECTOR AND TIMESLOTS -->
  <div appearance="standard" fxFlex="100" style="padding-top: 30px">
    <button
      id="apply-date"
      mat-raised-button
      color="primary"
      (click)="setDate()"
    >
      Apply
    </button>
  </div>
</div>

<!-- SPECIFIC WEEK -->
<!-- <mat-form-field
  id="specific-week-picker"
  class="hiddenDatePicker"
  style="visibility: hidden; width: 0; height: 0"
>
  <mat-date-range-input
    [formGroup]="periodSpecific"
    [rangePicker]="periodSpecificPicker"
  >
    <input matStartDate placeholder="Start date" formControlName="start" />
    <input matEndDate placeholder="End date" formControlName="end" />
  </mat-date-range-input>

  <mat-datepicker-toggle
    matSuffix
    [for]="periodSpecificPicker"
  ></mat-datepicker-toggle>

  <mat-date-range-picker
    #periodSpecificPicker
    touchUi
    (opened)="specificWeekChange('open')"
    (closed)="specificWeekChange('close')"
  >
    <mat-date-range-picker-actions>
      <button id="datepicker-cancel" mat-button matDateRangePickerCancel>
        Cancel
      </button>
      <button
        id="datepicker-apply"
        mat-raised-button
        color="primary"
        matDateRangePickerApply
      >
        Apply
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field> -->

<!-- DATE RANGE -->
<app-custom-date-picker
  #dateRangePicker
  [selectionStrategy]="displayFields.indexOf('trends')"
  (dateRangeChangeEvent)="onReceiveDateRangeChange($event)"
></app-custom-date-picker>
