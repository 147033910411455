import { Component, OnInit } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';
import { RestaurantsService } from '../../../services/restaurants.service';
import { ApiCallsService } from '../../../services/area.service';
import { sortByName } from '../../../utils/utils';
import { Area } from '../../../utils/CommonInterfaces';

@Component({
  selector: 'app-header-extra',
  templateUrl: './header-extra.component.html',
  styleUrls: ['./header-extra.component.scss'],
})
export class HeaderExtraComponent implements OnInit {
  public pageInfo;
  public selectedRestaurantId: string;
  public venuesList = [];
  public areaGroupList = <any>[];
  public showSearchBar = true;

  private areaGroups: Area[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private apiCallsService: ApiCallsService,
    private restaurantsService: RestaurantsService
  ) {
    this.restaurantsService.allVenuesSubject.subscribe(async (data) => {
      if (!data || data.length === 0) { return; }

      console.log('header-extra settings fetch', data);
      this.venuesList = data;
      this.getAreaGroups();
    });

    this.restaurantsService.selectedVenueSubject.subscribe((data) => {
      if (!data) { return; }
      this.selectedRestaurantId = String(data);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        // console.log('router events', event);
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;

        // Gets the latest venue updates
        // if (this.venuesList.length > 0) {
        //   this.venuesList = [...this.restaurantsService.allVenuesData];
        //   this.getAreaGroups();
        //   this.addVenueWithNoAreaToGroupList();
        // }
      });
  }

  async getAreaGroups(): Promise<void> {
    const areas: Area[] = await this.apiCallsService.fetchAreas();
    this.areaGroups = areas
      .sort(sortByName)
      .filter((area) => area.venues.length > 0);

    // Sort venues in area by name
    this.areaGroups.forEach(
      (area) => (area.venues = area.venues.sort(sortByName))
    );
    this.addVenueWithNoAreaToGroupList();
  }

  addVenueWithNoAreaToGroupList(): void {
    const venuesNotInArea = [];
    this.venuesList.forEach((venue) => {
      if (!venue.area) { venuesNotInArea.push(venue); }
    });

    if (venuesNotInArea.length > 0) {
      this.areaGroups.push({
        id: 0,
        name: 'No area assigned',
        venues: [...venuesNotInArea].sort(sortByName),
        updated_at: undefined,
        description: undefined,
        brand: undefined,
      });
    }

    this.areaGroupList = [...this.areaGroups].sort(sortByName);
  }

  filterVenues(venues: any[], value: string): any[] {
    return venues.filter((venue) =>
      venue.name.toLowerCase().includes(value.toLowerCase())
    );
  }

  filterAreaGroup(value: string): void {
    if (value) {
      const results = this.areaGroups
        .map((group) => ({
          name: group.name,
          venues: this.filterVenues(group.venues, value),
        }))
        .filter((group) => group.venues.length > 0);

      this.areaGroupList = results;
    } else {
      this.areaGroupList = [...this.areaGroups].sort(sortByName);
    }
  }

  restaurantSelect(id: MatSelectChange) {
    console.log('restaurantSelect', id);
    const venueId = id.value.toString();
    // Temporarlly disable the check below as it looks like it wont be needed
    // if (id !== this.selectedRestaurantId) {
    this.restaurantsService.processVenueUpdates(venueId);
    this.selectedRestaurantId = venueId;
    // }
  }

  openSettings(buttonType) {
    // this.router.navigate(['/venue-settings', this.selectedRestaurantId]);
    this.restaurantsService.toggleRightSidebar({
      status: true,
      type: buttonType,
    });
  }

  ngOnInit(): void {}
}
