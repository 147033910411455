<div
  fxLayout="row wrap"
  fxLayoutAlign="stretch"
  *ngIf="!statisticsParams.selectedDate"
>
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="spinner-wrapper">
      <mat-spinner [diameter]="50" [strokeWidth]="8"></mat-spinner>
    </div>
  </div>
</div>

<div class="row" *ngIf="statisticsParams.selectedDate && !showDateAlertMessage">
  <!-- DAILY VIEW -->
  <app-daily-view
    data-testid="app-daily-view"
    *ngIf="viewType === 'daily' && venueData"
    [statisticsParams]="statisticsParams"
    [venueData]="venueData"
    (timeslotsSelectedChange)="onReceiveDateChange($event)"
    (openSidebarEvent)="openSidebar()"
  ></app-daily-view>

  <!-- WEEKLY VIEW -->
  <app-weekly-view
    data-testid="app-weekly-view"
    *ngIf="viewType === 'weekly'"
    [statisticsParams]="statisticsParams"
    [venueData]="venueData"
    (weekDaysSelectedChange)="onReceiveDateChange($event)"
    (openSidebarEvent)="openSidebar()"
  ></app-weekly-view>

  <!-- TRENDS VIEWS -->
  <app-trends-view
    *ngIf="viewType === 'trends' && venueData"
    [statisticsParams]="statisticsParams"
    [venueData]="venueData"
    (weekDaysSelectedChange)="onReceiveDateChange($event)"
    (openSidebarEvent)="openSidebar()"
  ></app-trends-view>

  <!-- CROSS VIEW -->
  <app-cross-view
    *ngIf="viewType === 'cross' && venueData"
    [statisticsParams]="statisticsParams"
    [venueData]="venueData"
    (weekDaysSelectedChange)="onReceiveDateChange($event)"
    (openSidebarEvent)="openSidebar()"
  ></app-cross-view>

  <!-- EXPORT VIEW -->
  <app-export-view
          *ngIf="viewType === 'export' && venueData"
          [statisticsParams]="statisticsParams"
          [venueData]="venueData"
          (weekDaysSelectedChange)="onReceiveDateChange($event)"
          (openSidebarEvent)="openSidebar()"
  ></app-export-view>

  <!-- EXPORT VIEW -->
  <app-competition-view
          *ngIf="viewType === 'competition' && venueData"
          [statisticsParams]="statisticsParams"
          [venueData]="venueData"
          (weekDaysSelectedChange)="onReceiveDateChange($event)"
          (openSidebarEvent)="openSidebar()"
  ></app-competition-view>

  <!-- ============================================================== -->
  <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
  <!-- ============================================================== -->
  <mat-sidenav
    id="app-compare-with-sidebar"
    #end
    position="end"
    class="params-panel"
    mode="over"
    opened="{{ rightSidebarStatus }}"
    hasBackdrop="true"
  >
    <div class="scroll">
      <mat-nav-list>
        <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>

        <!-- DAILY VIEW CALENDAR -->
        <app-compare-with
          data-testid="app-compare-with-daily"
          #compareWithDaily
          *ngIf="viewType === 'daily'"
          [displayFields]="['singleDate']"
          [statisticsParams]="statisticsParams"
          [getPeriodType]="getPeriodType"
          (selectedDateChange)="onReceiveDateChange($event)"
        ></app-compare-with>

        <!-- WEEKLY, TRENDS & CROSS VIEW CALENDAR -->
        <app-compare-with
          data-testid="app-compare-with-weekly"
          #compareWithWeekly
          *ngIf="
            viewType === 'weekly' ||
            viewType === 'cross' ||
            viewType === 'trends' ||
            viewType === 'export' ||
            viewType === 'competition'
          "
          [displayFields]="compareWithDisplayFields"
          [statisticsParams]="statisticsParams"
          [getPeriodType]="getPeriodType"
          (selectedDateChange)="onReceiveDateChange($event)"
        ></app-compare-with>

        <!-- <app-compare-with
          data-testid="app-compare-with-weekly"
          #compareWithWeekly
          *ngIf="viewType === 'trends'"
          [displayFields]="['weekdaySelector', 'timeslotSelector', 'trends']"
          [statisticsParams]="statisticsParams"
          [getPeriodType]="getPeriodType"
          (selectedDateChange)="onReceiveDateChange($event)"
        ></app-compare-with> -->

        <!-- <mat-divider></mat-divider> -->
      </mat-nav-list>
    </div>
  </mat-sidenav>
</div>

<div
  id="date-alert-message"
  fxLayout="column"
  *ngIf="showDateAlertMessage && venueData"
  style="justify-content: center; width: 100%"
>
  <h2 style="text-align: center">
    Data is not yet available for the selected date(s). <br />
    <br />
    Latest date available is
    <code id="latest-date-available" class="latest_date_box">
      {{ formatDateToDDMMYYYY(venueData?.latestDataDate) }}
    </code>
  </h2>

  <button
    id="view-latest-date-button"
    mat-raised-button
    color="primary"
    (click)="loadLatestDate()"
    style="width: min-content; align-self: center"
  >
    View latest data
  </button>
</div>
