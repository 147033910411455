  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/0.5x/full_color@0.5x.png" class="large-logo">
            <h4 class="m-t-0">Welcome to Eyezilla Dashboard!</h4>
          </div>
          <div *ngIf="msg" class="bg-danger p-10 text-white m-b-15">{{ msg }}</div>

          <p class="text-center font-14">To get started, please create a password.</p>

          <div fxLayout="column" fxLayoutAlign="space-around">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput type="password" placeholder="Password" [formControl]="form.controls['password']">
              </mat-form-field>
              <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger support-text">Please enter a password.</small>
	            <small *ngIf="form.controls['password'].hasError('minlength') && form.controls['password'].touched" class="text-danger support-text">Password must be at least 8 characters.</small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Confirm password" [formControl]="form.controls['confirmPassword']" type="password">
              </mat-form-field>
              <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" class="text-danger support-text">Please confirm your password.</small>
              <small *ngIf="form.controls['confirmPassword'].errors?.matchTo" class="text-danger support-text">Password don't match.</small>
            </div>
            <button *ngIf="!loading" mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20" [disabled]="!form.valid">Set Password</button>
            <button *ngIf="loading" mat-raised-button color="primary" class="btn-block btn-lg m-t-20" [disabled]="!form.valid"><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button>

          </div>

        </form>
      </mat-card-content>
    </mat-card>
  </div>

