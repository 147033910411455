<mat-card class="pegasus-card mat-card-top-panel-override">
  <div
    fxFlex
    fxLayout="row wrap"
    fxLayoutAlign="space-between center no-wrap"
    style="
      width: 100%;
      height: fit-content;
      flex-wrap: nowrap;
      padding: 10px 24px;
    "
  >
    <div
      fxFlex
      fxLayoutAlign="space-between center no-wrap"
      style="font-size: 15px; font-weight: 400"
    >
      <span style="white-space: nowrap"> Date from </span>
      <strong id="header-date-start" style="margin: 0 5px">
        {{ dateRange.start || 'Not defined' }}
      </strong>
      to
      <strong id="header-date-end" style="margin: 0 5px">
        {{ dateRange.end || 'Not defined' }}
      </strong>

      <!-- Open calendar rightsidebar button -->
      <button
        id="edit-date"
        mat-icon-button
        style="margin-left: 10px"
        aria-label="Date configuration"
        alt="openSidebar"
        (click)="openRightSidebar()"
      >
        <img
          src="assets/images/pegasus-icons/Edit.svg"
          alt="openSidebar"
          class="settings-cog heading-color"
        />
      </button>



      <!-- Weekdays chips -->
      <div
              id="weekdays-chips-container"
              fxLayout="row"
              fxLayoutAlign="flex-end center"
              *ngIf="showWeekDaysChips && !isMobile"
              (click)="$event.stopPropagation()"
      >


        <mat-chip-list
                id="weekdays-chips"
                aria-label="Weekday selection"
                [multiple]="true"
                style="margin-right: 20px"
        >
          <ng-container *ngFor="let weekday of weekDaysChips">
            <mat-chip
                    style="cursor: pointer"
                    [selected]="checkWeekDaySelection(weekday)"
                    (click)="changeWeekDayChip(weekday)"
            >{{ weekday }}</mat-chip
            >
          </ng-container>
          <button
                  id="apply-weekdays-filter"
                  mat-raised-button
                  style="background-color: #3949ab; color: white; margin-right: 20px"
                  (click)="applyWeekDaysFilter($event)"
                  alt="applyWeekdayFilter"
                  *ngIf="
              weekDaysSelected.sort().join() !==
              statisticsParams.weekDaysSelected.sort().join()
            "
          >
            Apply
          </button>
        </mat-chip-list>

      </div>
    </div>
    <div
            fxFlex
            fxLayoutAlign="space-between center"
            style="margin-left: 20px"
    >
      <!-- Venue List multiple select -->
      <mat-form-field>
        <mat-label>Venues</mat-label>
        <mat-select
                [value]="[venueData]"
                multiple
                (selectionChange)="selectionTrendsGroup($event)"
        >
          <ng-container *ngFor="let venue of allVenues">
            <mat-option [value]="venue">{{ venue.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>

<!-- Used for E2E testing purposes only -->
<div id="timeslots-selected" style="display: none">
  {{ statisticsParams.timeslotsSelected.join(',') }}
</div>

<!-- GRAFANA VIEW -->
<app-grafana-charts-view
  data-testid="app-grafana-charts-view"
  [venueData]="venueData"
  [statisticsParams]="statisticsParams"
  [trendsGroup]="trendsGroup"
></app-grafana-charts-view>
