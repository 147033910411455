import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { ApiCallsService } from '../../../../services/area.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgStrapiAuthConfig } from '../../../../services/strapi/auth/ng-strapi-auth-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Area, User } from '../../../../utils/CommonInterfaces';
import { sortByName } from '../../../../utils/utils';

@Component({
  selector: 'app-area-settings',
  templateUrl: './area-settings.component.html',
  styleUrls: ['./area-settings.component.scss'],
})
export class AreaSettingsComponent implements OnInit {
  @Input() users: User[] = [];
  @Input() loggedInUser: User;
  @Output() updateUsers = new EventEmitter();
  @Output() refetchBrandSettings = new EventEmitter();

  public areas: Area[] = [];
  public displayedColumns: string[] = [
    'name',
    'type',
    'description',
    'actions',
  ];

  private areaTypes: Area[] = [];

  constructor(
    public dialog: MatDialog,
    private apiCallsService: ApiCallsService,
    private snackBar: MatSnackBar,

    @Inject('config') private config: NgStrapiAuthConfig
  ) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.AreaSettingsComponent = this;
    }

    if (!this.config && !this.config.apiUrl) {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
  }

  public openCreateAreaDialog() {
    const createAreaDialogRef = this.dialog.open(CreateAreaDialogComponent, {
      minWidth: '500px',
      disableClose: true,
      data: {
        areas: this.areaTypes,
        formValues: {
          name: '',
          type: '',
          description: '',
        },
      },
    });

    createAreaDialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'cancel') {
        return;
      }

      if (result.name && result.name !== '') {
        const createResponse = await this.apiCallsService.createArea(result);

        if (createResponse === 'error') {
          this.openSnackBar('Error creating area, please try again', 'Ok');
          return;
        }

        this.updateAreas();
        this.openSnackBar('Area created successfully', 'Ok');
      }
    });
  }

  public openEditAreaDialog(area) {
    // console.log('Edit Area:', area);
    const editAreaDialogRef = this.dialog.open(EditAreaDialogComponent, {
      minWidth: '500px',
      disableClose: true,
      data: {
        areas: this.areaTypes,
        formValues: {
          name: area.name,
          type: area.type,
          description: area.description,
        },
      },
    });

    editAreaDialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'cancel') {
        return;
      }

      // console.log(
      //   'data for area with id',
      //   area.id,
      //   'to update',
      //   JSON.stringify(result)
      // );
      result.id = area.id;
      const editResponse = await this.apiCallsService.editArea(result);

      if (editResponse === 'error') {
        this.openSnackBar('Error editing area, please try again', 'Ok');
        return;
      }

      this.updateAreas();
      this.openSnackBar('Area updated successfully', 'Ok');
    });
  }

  public openDeleteAreaDialog(id, name) {
    // console.log('Delete Area with id:', id);
    const deleteAreaDialogRef = this.dialog.open(DeleteAreaDialogComponent, {
      minWidth: '300px',
      disableClose: true,
      data: { id, name },
    });

    deleteAreaDialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'cancel') return;

      const deleteResponse = await this.apiCallsService.deleteArea(id);

      if (deleteResponse !== 'ok') {
        this.openSnackBar(deleteResponse, 'Ok');
        return;
      }

      this.updateAreas();
      this.openSnackBar('Area deleted successfully', 'Ok');
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3 * 1000,
    });
  }

  private async updateAreas(refetch: boolean = true) {
    console.log('area-settings fetchAreas()');
    this.areas = await this.apiCallsService.fetchAreas();
    this.areas = this.areas.sort(sortByName);
    refetch && this.refetchBrandSettings.emit();
  }

  ngOnInit() {
    this.updateAreas(false);
  }
}

// ---------------------------------------------
// CREATE AREA DIALOG
// ---------------------------------------------
@Component({
  selector: 'create-area-dialog',
  templateUrl: './create-area-dialog.html',
})
export class CreateAreaDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any // public createAreaDialogRef: MatDialogRef<CreateAreaDialogComponent>
  ) {}
}

//---------------------------------------------
// DELETE AREA DIALOG
//---------------------------------------------
@Component({
  selector: 'delete-area-dialog',
  templateUrl: './delete-area-dialog.html',
})
export class DeleteAreaDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

//---------------------------------------------
// EDIT AREA DIALOG
//---------------------------------------------
@Component({
  selector: 'edit-area-dialog',
  templateUrl: './edit-area-dialog.html',
})
export class EditAreaDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
