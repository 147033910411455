<mat-card class="pegasus-card" #mainChartContainer id="mainChartContainer">
  <mat-card-content>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      *ngIf="!iframeHeight"
    >
      <mat-spinner [diameter]="50" [strokeWidth]="8"></mat-spinner>
    </div>

    <div *ngIf="false" style="font-size: 15px; font-weight: 400;width: 100%;text-align-last: end;" >
      <span style="white-space: nowrap"> <button mat-button>Download/Export</button></span>
    </div>

    <div
      [ngStyle]="{ opacity: iframeHeight !== 0 ? 1 : 0 }"
      fxLayout="column"
      [ngStyle]="{ 'justify-content': 'center', 'height.px': iframeHeight }"
      #iframe
    >
      <iframe
        *ngIf="statisticsParams && venueData && !forceHideCharts"
        [src]="grafanaUrlBuilder('value_data') | safe : 'resourceUrl'"
        frameborder="0"
        class="grafanaFrame"
        fxFlex="grow"
      ></iframe>
    </div>
  </mat-card-content>
</mat-card>
