<div
  class="flippable-card"
  fxFlexAlign="start"
  fxFlex="100"
  fxFlexOrder="0"
  fxFlex.lt-xs="0 1 calc(100%)"
  fxFlex.gt-md="0 1 calc(100%)"
  fxFlex.gt-xs="100"
  style="max-width: calc(100%)"
>
  <mat-card class="pegasus-card front">
    <mat-card-content class="pegasus-card front">
      <button
        data-testid="invite-users-button"
        mat-raised-button
        color="primary"
        style="width: fit-content; margin-bottom: 5px"
        (click)="inviteUser()"
      >
        <mat-icon>send</mat-icon> Invite users
      </button>

      <div fxLayout="column" class="table-card-holder">
        <table
          data-testid="users-table"
          mat-table
          [dataSource]="users"
          class="mat-elevation-z0"
        >
          <!-- Note that these columns can be defined in any order.
										  The actual rendered columns are set as a property on the row definition" -->
          <!-- ['name', 'email', 'active', 'venueAccess']-->

          <!-- Position Column -->
          <ng-container matColumnDef="username" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.username }}
            </td>
          </ng-container>

          <!-- email column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
              {{ element.email }}
            </td>
          </ng-container>

          <!-- last accessed column -->
          <ng-container matColumnDef="lastAccessed">
            <th mat-header-cell *matHeaderCellDef>Last Accessed</th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAccessed.split('T')[0] }}
              <!-- <br />
                      <span style="font-size: small">{{
                        element.lastAccessed.split('T')[1]?.slice(0, 8)
                      }}</span> -->
            </td>
          </ng-container>

          <!-- joined column -->
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Joined</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="!element.active">No</span>
              <span *ngIf="element.active">Yes</span>
            </td>
          </ng-container>

          <!-- live view column -->
          <!--                  <ng-container matColumnDef="viewOnly">-->
          <!--                    <th mat-header-cell *matHeaderCellDef>Live view only?</th>-->
          <!--                    <td-->
          <!--                      mat-cell-->
          <!--                      *matCellDef="let element"-->
          <!--                      style="text-align: center"-->
          <!--                    >-->
          <!--                      <mat-slide-toggle-->
          <!--                        [(ngModel)]="element.viewOnly"-->
          <!--                        (change)="viewOnlyChange($event, element.id)"-->
          <!--                        [disabled]="element.isAdmin"-->
          <!--                      ></mat-slide-toggle>-->
          <!--                    </td>-->
          <!--                  </ng-container>-->

          <!-- role column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field id="role-select">
                <mat-select
                  [(ngModel)]="element.role"
                  name="role"
                  (selectionChange)="changeUserAccess($event, element)"
                >
                  <mat-option *ngFor="let role of roles" [value]="role.type">
                    {{ role.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- venue access column -->
          <ng-container matColumnDef="venuesAccess">
            <th mat-header-cell *matHeaderCellDef>Venue Access</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field id="venue-access-select">
                <mat-select
                  [(ngModel)]="element.venuesAccess"
                  name="venue"
                  (selectionChange)="changeUserAccess($event, element)"
                  [disabled]="!(element.role === 'venue_manager')"
                >
                  <mat-option
                    *ngFor="let venue of brandVenues"
                    [value]="venue.name"
                    [disabled]="venue.name === 'All'"
                    [ngStyle]="{
                      background:
                        venue.name === 'All' ? 'rgba(0,255,0,0.1)' : ''
                    }"
                  >
                    {{ venue.name }}
                  </mat-option>
                  <mat-option
                    [value]="'All'"
                    *ngIf="!(element.role === 'venue_manager')"
                  >
                    All
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- area access column -->
          <ng-container matColumnDef="areaAccess">
            <th mat-header-cell *matHeaderCellDef>Area Access</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field id="area-access-select">
                <mat-select
                  [(ngModel)]="element.areaAccess"
                  name="area"
                  (selectionChange)="changeUserAccess($event, element)"
                  [disabled]="!(element.role === 'area_manager')"
                >
                  <ng-container *ngFor="let area of brandAreas">
                    <mat-option
                      [value]="area.id.toString()"
                      [disabled]="
                        area.id === 'no_area' ||
                        area.id === 'all' ||
                        area.venues.length === 0
                      "
                    >
                      {{ area.name }}
                    </mat-option>
                  </ng-container>

                  <mat-option
                    [value]="'all'"
                    *ngIf="!(element.role === 'area_manager')"
                  >
                    All
                  </mat-option>

                  <mat-option
                    [value]="'no_area'"
                    *ngIf="!(element.role === 'area_manager')"
                  >
                    Not in area
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- actions currently only delete btn column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                color="warn"
                aria-label="Delete user"
                (click)="deleteUser(element.username, element.id)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
