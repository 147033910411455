<div
  class="flippable-card"
  fxFlexAlign="start"
  fxFlex="100"
  fxFlexOrder="0"
  fxFlex.lt-xs="0 1 calc(100%)"
  fxFlex.gt-md="0 1 calc(50%)"
  fxFlex.gt-xs="100"
  style="max-width: calc(100%)"
>
  <mat-card class="pegasus-card front">
    <mat-card-content class="pt-0">
      <div fxLayout="column">
        <mat-list>
          <div data-testid="about-me" mat-subheader>About Me</div>
          <mat-list-item>
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <div data-testid="about-me-username" mat-line>
              {{ userStatus?.accountData?.username }}
            </div>
            <div data-testid="about-me-email" mat-line>
              {{ userStatus?.accountData?.email }}
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <div data-testid="dashboard-role" mat-subheader>Dashboard Role</div>
          <mat-list-item>
            <mat-icon mat-list-icon>verified_user</mat-icon>
            <div data-testid="dashboard-role-name" mat-line>
              {{ userStatus?.accountData?.role?.name }}
            </div>
            <!-- <div mat-line style="white-space: break-spaces"> -->
            <div data-testid="dashboard-role-description" mat-line>
              {{ userStatus?.accountData?.role?.description }}
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
</div>
