import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgStrapiAuthService } from '../../services/strapi/auth/ng-strapi-auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  public form: FormGroup;
  public msg;
  public success = false;
  loading = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: NgStrapiAuthService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        null,
        // , CustomValidators.email
        Validators.compose([Validators.required]),
      ],
    });
  }

  async onSubmit() {
    this.loading = true;
    try {
      const result = await this.authService.forgotPassword(
        this.form.value.email
      );
      console.log('result', result);
      if (result.ok) {
        this.success = true;
        this.loading = false;
      }
    } catch (e) {
      this.loading = false;

      const errorMsg = e;
      // if('This email does not exist.'){
      //   return this.msg = 'Email не найден';
      // }
      this.msg = errorMsg;
    }
    // this.router.navigate(['/authentication/login']);
  }
}
