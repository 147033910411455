import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { HttpClient } from '@angular/common/http';
import { NgStrapiAuthConfig } from '../../../../services/strapi/auth/ng-strapi-auth-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiCallsService } from '../../../../services/area.service';
import { Area, Venue } from '../../../../utils/CommonInterfaces';
import { sortByName } from '../../../../utils/utils';

@Component({
  selector: 'app-venue-settings',
  templateUrl: './venue-settings.component.html',
  styleUrls: ['./venue-settings.component.scss'],
})
export class VenueSettingsComponent implements OnInit {
  @Input() set venues(venues: Venue[]) {
    this.processVenueSetInput(venues);
  }

  get venues(): Venue[] {
    return this.venuesWithFilteredSettings;
  }

  @Input() withCard = true;
  @Input() changeDisabled = false;
  @Input() hideStaticColumns = false;
  @Output() refetchBrandSettings = new EventEmitter();

  public venueTypes: string[] = [
    'Transport_Hub',
    'Flagship',
    'Regional',
    'Local',
  ];
  public availableAreas: Area[] = [];
  public displayedColumns: string[] = [];
  public dynamicColumns: string[] = [];
  public columnNamesFromKey: {} = {
    targetHoursOfDemand: {
      name: 'Hours of Bottlenecks Per day',
      description:
        'How many hour per day venue is expected to have constant flow of guests',
    },
    targetMaxTPH: {
      name: 'Target TPH',
      description: 'Target TPH for the venue',
    },
    targetCoversPerDay: {
      name: 'Target Daily Covers',
      description: 'Target Daily Covers for the venue',
    },
    targetTotalDuration: {
      name: 'Total Duration',
      description: 'Total expected duration from tray pickup to end of payment',
    },
    targetPeakTotalDuration: {
      name: 'Peak Total Duration',
      description:
        'Total expected duration from tray pickup to end of payment during peak hours',
    },
    targetQueueOccupancy: {
      name: 'Queue Occupancy',
      description: 'Average queue occupancy for the venue throughout the day',
    },

    targetTableOccupancy: {
      name: 'Table Occupancy',
      description: 'Average table occupancy for the venue throughout the day',
    },

    targetConversion: {
      name: 'Passerby Conversion',
      description: 'Average % of passerby converted to guests entered for the venue throughout the day',
    },

    targetPasserBysPerDay: {
      name: 'Passerby Per Day',
      description: 'Average passerby for the venue throughout the day',
    },
    targetPeakStationDurations__yusen: {
      name: 'Peak Yusen',
      description: 'Peak Yusen duration',
    },
    targetPeakStationDurations__fridge: {
      name: 'Peak Fridge',
      description: 'Peak Fridge duration',
    },
    targetPeakStationDurations__cashier: {
      name: 'Peak Cashier',
      description: 'Peak Cashier duration',
    },
    targetPeakStationDurations__tempura: {
      name: 'Peak Tempura',
      description: 'Peak Tempura duration',
    },
    targetPeakStationDurations__to_fridge: {
      name: 'Peak To Fridge',
      description: 'Peak To Fridge duration',
    },
    targetPeakStationDurations__to_tempura: {
      name: 'Peak To Tempura',
      description: 'Peak To Tempura duration',
    },
    targetStationDurations__yusen: {
      name: 'Yusen',
      description: 'Yusen duration',
    },
    targetStationDurations__fridge: {
      name: 'Fridge',
      description: 'Fridge duration',
    },
    targetStationDurations__cashier: {
      name: 'Cashier',
      description: 'Cashier duration',
    },
    targetStationDurations__tempura: {
      name: 'Tempura',
      description: 'Tempura duration',
    },
    targetStationDurations__to_fridge: {
      name: 'To Fridge',
      description: 'To Fridge duration',
    },
    targetStationDurations__to_tempura: {
      name: 'To Tempura',
      description: 'To Tempura duration',
    },
  };

  private venuesWithFilteredSettings: Venue[];

  // Add new columns that don't belong to displayedColumns here
  private defaultDisplayedColumns: string[] = [
    'name',
    'area',
    'pacesetter',
    'type',
  ];
  private displayColumnAction: string = 'actions'; // must always be at the end of array

  constructor(
    @Inject('config') private config: NgStrapiAuthConfig,
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private apiCallsService: ApiCallsService
  ) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.VenueSettingsComponent = this;
    }
  }

  private async processVenueSetInput(venues: Venue[]) {
    await this.fetchAvailableAreas();
    const tempDisplayCols = [];
    if (venues) {
      this.venuesWithFilteredSettings = venues.map((venue) => {
        const chartsSettings = venue.chartsSettings;
        const tempTableValues = {};
        Object.keys(chartsSettings).forEach((key) => {
          if (
            [
              'targetHoursOfDemand',
              'targetMaxTPH',
              'targetTotalDuration',
              'targetPeakTotalDuration',
              'targetQueueOccupancy',
                'targetCoversPerDay',
              'targetTableOccupancy',
              'targetConversion',
              'targetPasserBysPerDay'
            ].includes(key)
          ) {
            if (!tempDisplayCols.includes(key)) {
              tempDisplayCols.push(key);
            }

            // this.tableValues[key] = this.chartsSettings[key];
            tempTableValues[key] = this.convertDisplayedValue(
              chartsSettings[key],
              chartsSettings[key + 'Params'].storedUnit,
              chartsSettings[key + 'Params'].displayUnit
            );
            tempTableValues[key + 'DisplayUnit'] =
              chartsSettings[key + 'Params'].displayUnit;
            tempTableValues[key + 'StoredUnit'] =
              chartsSettings[key + 'Params'].storedUnit;
            tempTableValues[key + 'Disabled'] =
              !!chartsSettings[key + 'Params'].disabled;
            tempTableValues[key + 'ParentKey'] = null;
          }

          if (
            key === 'targetPeakStationDurations' ||
            key === 'targetStationDurations'
          ) {
            const prefix = key + '__';
            Object.keys(chartsSettings[key]).forEach((stationKey) => {
              if (!tempDisplayCols.includes(prefix + stationKey)) {
                tempDisplayCols.push(prefix + stationKey);
              }
              tempTableValues[prefix + stationKey] = this.convertDisplayedValue(
                chartsSettings[key][stationKey],
                chartsSettings[key + 'Params'].storedUnit,
                chartsSettings[key + 'Params'].displayUnit
              );
              tempTableValues[prefix + stationKey + 'DisplayUnit'] =
                chartsSettings[key + 'Params'].displayUnit;
              tempTableValues[prefix + stationKey + 'StoredUnit'] =
                chartsSettings[key + 'Params'].storedUnit;
              tempTableValues[prefix + stationKey + 'Disabled'] =
                !!chartsSettings[key + 'Params'].disabled;
              tempTableValues[prefix + stationKey + 'ParentKey'] = key;
            });
          }
        });
        venue.tableValues = tempTableValues;
        venue.originalValues = JSON.parse(JSON.stringify(tempTableValues));
        return venue;
      });

      this.dynamicColumns = tempDisplayCols;

      this.displayedColumns = this.hideStaticColumns
        ? tempDisplayCols
        : [
            ...this.defaultDisplayedColumns,
            ...tempDisplayCols,
            this.displayColumnAction,
          ];
    }
  }

  public async changeVenueArea($event: MatSelectChange, id): Promise<void> {
    try {
      await this.httpClient
        .post(this.config.apiUrl + '/venues/setArea', {
          venueId: Number(id),
          areaId: Number($event.value),
        })
        .toPromise();

      this.openSnackBar('Venue Area updated', 'Ok');
      this.refetchBrandSettings.emit();
    } catch (e) {
      console.error('Error updating Area in Venue:', e);
      this.openSnackBar(e, 'Ok');
      this.refetchBrandSettings.emit();
    }
  }

  public async changeVenueType($event: MatSelectChange, id): Promise<void> {
    try {
      await this.httpClient
        .post(this.config.apiUrl + '/venues/setType', {
          venueId: Number(id),
          type: $event.value,
        })
        .toPromise();

      this.openSnackBar('Venue Type updated', 'Ok');
      this.refetchBrandSettings.emit();
    } catch (e) {
      console.error('Error updating Type in Venue:', e);
    }
  }

  public async changeVenuePacesetter(
    $event: MatSelectChange,
    id
  ): Promise<void> {
    console.log('$event to change to', $event);
    console.log('venue to change', id);

    try {
      const result: any = await this.httpClient
        .post(this.config.apiUrl + '/venues/setPacesetter', {
          venueId: Number(id),
          pacesetterId: Number($event.value),
        })
        .toPromise();
      console.log('result', result);
      this.openSnackBar('Venue pacesetter updated', 'Ok');
      this.refetchBrandSettings.emit();
    } catch (e) {
      console.log('e', e);
      try {
        this.openSnackBar(e, 'Ok');
      } catch (e) {}
    }
  }

  public hasValueChanged(element): boolean {
    const chartsSettingsValueToUpdate = this.detectedChangeFromOg(element);
    // console.log('chartsSettingsValueToUpdate', chartsSettingsValueToUpdate);
    // console.log('Object.keys(chartsSettingsValueToUpdate).length > 0', Object.keys(chartsSettingsValueToUpdate).length);
    return Object.keys(chartsSettingsValueToUpdate).length > 0;
  }

  public async applyVenueChanges(element): Promise<void> {
    console.log('element', element);
    const changes = this.detectedChangeFromOg(element);
    console.log('changes', changes);
    try {
      const result: any = await this.httpClient
        .post(this.config.apiUrl + '/venues/updateVenueChartsSettings', {
          venueId: Number(element.id),
          changes: changes,
        })
        .toPromise();
      console.log('result', result);
      this.openSnackBar('Venue settings changed', 'Ok');
      this.refetchBrandSettings.emit();
    } catch (e) {
      console.log('e', e);
      try {
        this.openSnackBar(e, 'Ok');
      } catch (e) {}
    }
  }

  private async fetchAvailableAreas(): Promise<void> {
    try {
      console.log('venue settings fetchAreas()');
      const areas = await this.apiCallsService.fetchAreas();
      this.availableAreas = areas.sort(sortByName);
    } catch (err) {
      console.error(err);
    }
  }

  private async fetchAreasById(id: number): Promise<any> {
    try {
      const results: any = await this.httpClient
        .get(this.config.apiUrl + `/areas/${Number(id)}`, {})
        .toPromise();
      console.log('fetchAreasById result', results);
      // return results;
    } catch (e) {
      console.error('Error fetching Areas', e);
      // return [];
    }
  }

  private convertDisplayedValue(value, storedUnit, displayUnit) {
    if (storedUnit === displayUnit) {
      return value;
    }

    if (storedUnit === 'sec' && displayUnit === 'min') {
      value = value / 60;
    }
    if (storedUnit === 'sec' && displayUnit === 'hours') {
      value = value / 3600;
    }
    if (storedUnit === 'ms' && displayUnit === 'sec') {
      value = value / 1000;
    }
    if (storedUnit === 'ms' && displayUnit === 'min') {
      value = value / 1000 / 60;
    }
    if (storedUnit === 'ms' && displayUnit === 'hours') {
      value = value / 1000 / 60 / 60;
    }
    return Math.round(value * 100) / 100;
  }

  private convertStoredValue(value, storedUnit, displayUnit) {
    if (storedUnit === displayUnit) {
      return value;
    }

    if (storedUnit === 'sec' && displayUnit === 'min') {
      value = value * 60;
    }
    if (storedUnit === 'sec' && displayUnit === 'hours') {
      value = value * 3600;
    }
    if (storedUnit === 'ms' && displayUnit === 'sec') {
      value = value * 1000;
    }
    if (storedUnit === 'ms' && displayUnit === 'min') {
      value = value * 1000 * 60;
    }
    if (storedUnit === 'ms' && displayUnit === 'hours') {
      value = value * 1000 * 60 * 60;
    }
    return Math.round(value);
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3 * 1000,
    });
  }

  private detectedChangeFromOg(element): {} {
    const chartsSettingsValueToUpdate = {};
    Object.keys(element.tableValues).forEach((key) => {
      if (
        key.includes('DisplayUnit') ||
        key.includes('StoredUnit') ||
        key.includes('ParentKey')
      ) {
        return;
      }
      const tableValue = Number(element.tableValues[key]);
      const originalValue = Number(element.originalValues[key]);
      if (tableValue !== originalValue) {
        // console.log('key', key);
        // console.log('tableValue', tableValue);
        // console.log('originalValue', originalValue);
        const unit = element.tableValues[key + 'DisplayUnit'];
        const storedUnit = element.tableValues[key + 'StoredUnit'];
        const parentKey = element.tableValues[key + 'ParentKey'];
        let valueNew;
        if (unit === storedUnit) {
          valueNew = tableValue;
        } else {
          valueNew = this.convertStoredValue(tableValue, storedUnit, unit);
        }
        const transformedKey = key.includes('__')
          ? key.slice(key.indexOf('__') + 2)
          : key;
        if (parentKey) {
          if (!chartsSettingsValueToUpdate[parentKey]) {
            chartsSettingsValueToUpdate[parentKey] = {};
          }
          chartsSettingsValueToUpdate[parentKey][transformedKey] = valueNew;
        } else {
          chartsSettingsValueToUpdate[transformedKey] = valueNew;
        }
      }
    });
    // console.log('chartsSettingsValueToUpdate', chartsSettingsValueToUpdate);
    return chartsSettingsValueToUpdate;
  }

  ngOnInit(): void {
    // console.log('venue-settings onChanges', changes);
    // this.fetchAvailableAreas();
    // this.refetchBrandSettings.emit();
  }
}
