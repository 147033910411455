import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-my-profile-settings',
  templateUrl: './my-profile-settings.component.html',
  styleUrls: ['./my-profile-settings.component.scss'],
})
export class MyProfileSettingsComponent implements OnInit {
  @Input() userStatus: any = {};

  constructor() {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.MyProfileSettingsComponent = this;
    }
  }

  ngOnInit(): void {
    console.log('MyProfile userStatus', this.userStatus);
  }
}
