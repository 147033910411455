import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  DateRange,
  StatisticsParams,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-weekly-view',
  templateUrl: './weekly-view.component.html',
  styleUrls: ['./weekly-view.component.scss'],
})
export class WeeklyViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() weekDaysSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public dateRange: DateRange;
  public weekDaysChips: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  public showWeekDaysChips = true;
  public isMobile = false;
  public weekDaysSelected: string[] = [];
  public weekDaysSelectedOld: string[] = [];

  constructor(private router: Router) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.WeeklyViewComponent = this;
    }
  }

  applyWeekDaysFilter(event) {
    event.stopPropagation();

    this.weekDaysSelectedChange.emit({
      ...this.statisticsParams,
      weekDaysSelected: this.weekDaysSelected,
    });
  }

  checkWeekDaySelection(weekDay: string) {
    if (weekDay !== undefined) {
      return this.weekDaysSelected.includes(
        String(moment().day(weekDay).format('e'))
      );
    } else {
      return false;
    }
  }

  changeWeekDayChip(weekDay: string) {
    const weekDayIndex = String(moment().day(weekDay).format('e'));
    let newWeekDaysSelected = [];

    if (this.weekDaysSelected.includes(weekDayIndex)) {
      newWeekDaysSelected = this.weekDaysSelected.filter(
        (e) => e !== weekDayIndex
      );
    } else {
      newWeekDaysSelected = [...this.weekDaysSelected, weekDayIndex];
    }

    // Make sure there is always one day selected as minimum
    if (newWeekDaysSelected.length === 1) {
      this.weekDaysSelectedOld = [...newWeekDaysSelected];
    }
    if (newWeekDaysSelected.length === 0) {
      newWeekDaysSelected = [...this.weekDaysSelectedOld];
      // return;
    }

    this.weekDaysSelected = [...newWeekDaysSelected];
  }

  openDailyView() {
    this.router.navigate(['/statistics-daily']);
  }

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams } = change;

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      const week = moment(
        statisticsParams.currentValue.selectedDate,
        'DD/MM/YYYY'
      ).clone();
      const start = week.startOf('isoWeek').clone();
      const end = week.endOf('isoWeek').clone();

      this.dateRange = {
        start: start.format('DD/MM/YYYY'),
        end: end.format('DD/MM/YYYY'),
      };
    }
    this.weekDaysSelected = [...this.statisticsParams.weekDaysSelected];
  }
}
