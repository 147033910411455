<span class="central-box" style="align-items: center">
  <div
    *ngIf="pageInfo?.title === 'Statistics' || pageInfo?.title === 'Live View'"
    class="align-self-center"
    fxFlex="none"
    fxFlex.gt-sm="none"
    fxFlex.gt-xs="none"
    style="display: inline-flex; background: transparent"
  >
    <mat-form-field class="restaurantTitleSelector">
      <mat-select
        [panelClass]="'venueSearchSelector'"
        [value]="selectedRestaurantId"
        (selectionChange)="restaurantSelect($event)"
      >
        <mat-option>
          <ngx-mat-select-search
            ngModel
            (ngModelChange)="filterAreaGroup($event)"
            placeholderLabel="Search venue"
            noEntriesFoundLabel="No matching venue found"
            class="restaurantTitleSelector__searchInput"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-optgroup
          *ngFor="let area of areaGroupList"
          [label]="area.name"
          class="restaurantTitleSelector__optionGroup"
        >
          <mat-option
            *ngFor="let venue of area.venues"
            [value]="venue.id.toString()"
          >
            <div fxFlex fxLayoutAlign="start center">
              {{ venue.name }}
              <span
                style="font-size: small; margin-left: 0.5em"
                *ngIf="venue.type"
              >
                - {{ venue.type.replace('_', ' ') }}
              </span>
            </div>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>

  <span fxFlex></span>

  <button
    *ngIf="pageInfo?.topRightButtonType === 'calendar'"
    mat-icon-button
    style="right: -25px"
    [ngStyle]="{ opacity: venuesList.length > 0 ? 1 : 0.5 }"
    [disabled]="venuesList.length > 0 ? false : true"
    (click)="openSettings(pageInfo?.topRightButtonType)"
  >
    <img
      src="assets/images/pegasus-icons/Calandar.svg"
      alt="settings"
      class="settings-cog"
    />
  </button>

  <button
    *ngIf="pageInfo?.topRightButtonType === 'full-screen'"
    mat-icon-button
    class="align-self-end"
    style="height: auto"
    (click)="openSettings(pageInfo?.topRightButtonType)"
  >
    <img
      src="assets/images/pegasus-icons/expand-resize.svg"
      alt="settings"
      class="settings-cog"
    />
  </button>
</span>
