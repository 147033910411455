import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  MatDateRangeSelectionStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';

@Injectable()
export class WeekRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this.createWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this.createWeekRange(activeDate);
  }

  private createWeekRange(date: any | null): DateRange<D> {
    if (date) {
      const start = date.clone().startOf('isoWeek');
      const end = date.endOf('isoWeek');
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: 'custom-date-picker.html',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useFactory: (
        comp: DateRangePickerSelection,
        adapter: DateAdapter<unknown>
      ) => {
        return comp.selectionStrategy
          ? new WeekRangeSelectionStrategy(adapter)
          : null;
      },
      deps: [DateRangePickerSelection, DateAdapter],
    },
  ],
})
export class DateRangePickerSelection {
  @Input() selectionStrategy: boolean = false;
  @Output() dateRangeChangeEvent = new EventEmitter();

  @ViewChild('picker') picker;

  public dateRange: FormGroup;

  constructor() {
    this.dateRange = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
  }

  open() {
    this.picker.open();
  }

  close() {
    this.picker.close();
  }

  dateRangeChange() {
    this.dateRangeChangeEvent.emit(this.dateRange.value);
  }
}
