<mat-form-field
  id="date-range-picker"
  class="hiddenDatePicker"
  style="visibility: hidden; width: 0; height: 0"
>
  <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
    <input matStartDate placeholder="Start date" formControlName="start" />
    <input matEndDate placeholder="End date" formControlName="end" />
  </mat-date-range-input>

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

  <mat-date-range-picker
    #picker
    touchUi
    (opened)="dateRangeChange()"
    (closed)="dateRangeChange()"
  >
    <mat-date-range-picker-actions>
      <button id="datepicker-cancel" mat-button matDateRangePickerCancel>
        Cancel
      </button>
      <button
        id="datepicker-apply"
        mat-raised-button
        color="primary"
        matDateRangePickerApply
      >
        Apply
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
