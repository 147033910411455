<div
  class="flippable-card"
  fxFlexAlign="start"
  fxFlex="100"
  fxFlexOrder="0"
  fxFlex.lt-xs="0 1 calc(100%)"
  fxFlex.gt-md="0 1 calc(100%)"
  fxFlex.gt-xs="100"
  style="max-width: calc(100%)"
>
  <mat-card class="pegasus-card front">
    <mat-card-content class="">
      <button
        data-testid="create-area-button"
        mat-raised-button
        color="primary"
        style="width: fit-content"
        (click)="openCreateAreaDialog()"
      >
        <mat-icon>add</mat-icon> Create Area
      </button>

      <div
        *ngIf="areas.length > 0; else elseBlock"
        fxLayout="column"
        class="table-card-holder"
      >
        <table
          data-testid="areas-table"
          mat-table
          [dataSource]="areas"
          class="mat-elevation-z0"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.type || 'No type assigned' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              {{ element.description || 'No description found' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                data-testid="edit-area-button"
                mat-icon-button
                color="accent"
                aria-label="Edit area"
                (click)="openEditAreaDialog(element)"
              >
                <mat-icon>edit</mat-icon>
              </button>

              <button
                data-testid="delete-area-button"
                mat-icon-button
                color="warn"
                aria-label="Delete area"
                (click)="openDeleteAreaDialog(element.id, element.name)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- <p>{{ areasStr }}</p> -->
      </div>

      <ng-template #elseBlock>
        <div style="margin-top: 20px">No areas found, please create one</div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
