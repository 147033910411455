import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DateRange,
  StatisticsParams,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { RestaurantsService } from '../../../../services/restaurants.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cross-view',
  templateUrl: './cross-view.component.html',
  styleUrls: ['./cross-view.component.scss'],
})
export class CrossViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() weekDaysSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public dateRange: DateRange;
  public weekDaysSelected: string[] = [];
  public weekDaysSelectedOld: string[] = [];
  public allVenues: Venue[] = [];
  public venuesGroupA: Venue[] = [];
  public venuesGroupB: Venue[] = [];
  public selectedVenuePaceSetter: Venue;

  constructor(private restaurantsService: RestaurantsService) {}

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }
  checkSelected(venue: Venue, group: Venue[]) {
    return group.findIndex((v) => v.id === venue.id) !== -1 ? true : false;
  }

  selectionGroupA($event) {
    this.venuesGroupA = [...$event.value];
  }
  selectionGroupB($event) {
    this.venuesGroupB = [...$event.value];
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams } = change;
    this.weekDaysSelected = [...this.statisticsParams.weekDaysSelected];

    this.allVenues = [...this.restaurantsService.allVenuesData];

    this.selectedVenuePaceSetter = this.allVenues.find(
      (venue) => this.venueData.pacesetter_venue.id === venue.id
    );

    if (statisticsParams && statisticsParams.firstChange) {
      this.venuesGroupA.push(this.venueData);
      this.venuesGroupB.push(this.selectedVenuePaceSetter);
    }

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      const week = moment(
        statisticsParams.currentValue.selectedDate,
        'DD/MM/YYYY'
      ).clone();
      const start = week.startOf('isoWeek').clone();
      const end = week.endOf('isoWeek').clone();

      this.dateRange = {
        start: start.format('DD/MM/YYYY'),
        end: end.format('DD/MM/YYYY'),
      };
    }
  }
}
