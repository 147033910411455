<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div *ngIf="!loaded">
  <div class="preloader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <!--    -->
</div>
<div
  class="main-container"
  [dir]="dir"
  [ngClass]="{
    minisidebar: minisidebar,
    boxed: boxed,
    danger: danger,
    blue: blue,
    green: green,
    dark: dark
  }"
>
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header" *ngIf="mobileQuery.matches">
      <a class="navbar-brand" href="/">
        <!-- Logo icon -->
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img
            src="../../../assets/images/eyezilla/eyezilla_logo_white_old.png"
            alt="homepage"
            style="height: 41px"
            class="dark-logo"
          />
          <!-- Light Logo icon -->
          <img
            src="../../../assets/images/eyezilla/eyezilla_logo_white_old.png"
            alt="homepage"
            style="height: 41px"
            class="light-logo"
          />
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span fxShow="false" fxShow.gt-xs>
          <!-- dark Logo text -->
          <img
            src="../../../assets/images/eyezilla/eyezilla_white_full.png"
            alt="homepage"
            style="height: 24px; margin-top: 6px"
            class="dark-logo"
          />
          <!-- Light Logo text -->
          <img
            src="../../../assets/images/eyezilla/eyezilla_white_full.png"
            class="light-logo"
            style="height: 24px; margin-top: 6px"
            alt="homepage"
          />
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <button
      mat-icon-button
      (click)="snav.toggle()"
      value="sidebarOpened"
      style="z-index: 9999"
    >
      <img
        src="assets/images/pegasus-icons/menu-left.svg"
        alt="settings"
        class="settings-cog svg-to-white"
      />
    </button>
    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <!--        <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">-->
    <!--            <mat-icon>search</mat-icon>-->
    <!--        </button>-->
    <app-header-extra
      data-testid="app-header-extra"
      class="app-header-extra"
    ></app-header-extra>
    <form class="app-search" [ngClass]="{ 'show-search': showSearch }">
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="cl-srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>

    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->

    <!--        <app-header></app-header>-->

    <!-- ============================================================== -->
    <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!--        <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">-->
    <!--            <mat-icon>settings</mat-icon>-->
    <!--        </button>-->
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container autosize
    class="example-sidenav-container main-sidenav-container-fix"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      fixedInViewport="true"
      #snav
      id="snav"
      class="pl-xs left-sidenav"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches"
      (click)="hideMobile(snav)"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
      [perfectScrollbar]="config"
      [style.marginTop.px]="64"
    >
      <app-sidebar
        data-testid="app-sidebar"
        class="app-sidebar"
        [email]="userData?.accountData?.email"
        [fullName]="userData?.accountData?.full_name"
      ></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!--        <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">-->
    <!--            <div class="scroll">-->
    <!--                <mat-nav-list>-->

    <!--                    <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>-->
    <!--                    </mat-list-item>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>-->
    <!--                    </mat-list-item>-->
    <!--                    &lt;!&ndash;<mat-list-item>-->
    <!--                    <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>-->
    <!--                  </mat-list-item>    &ndash;&gt;-->
    <!--                    <mat-divider></mat-divider>-->
    <!--                    <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red</mat-checkbox>-->
    <!--                    </mat-list-item>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal-->
    <!--                            Green</mat-checkbox>-->
    <!--                    </mat-list-item>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue</mat-checkbox>-->
    <!--                    </mat-list-item>-->
    <!--                    <mat-list-item>-->
    <!--                        <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>-->
    <!--                    </mat-list-item>-->
    <!--                </mat-nav-list>-->
    <!--            </div>-->
    <!--        </mat-sidenav>-->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">
      <!--				<app-breadcrumb></app-breadcrumb>-->
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
        <!--                    <app-footer location="dashboard"></app-footer>-->
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
