import {
  Component,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DateRange,
  StatisticsParams,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { RestaurantsService } from '../../../../services/restaurants.service';
import {HttpClient} from '@angular/common/http';
import {NgStrapiAuthConfig} from '../../../../services/strapi/auth/ng-strapi-auth-config';

@Component({
  selector: 'app-competition-view',
  templateUrl: './competition-view.component.html',
  styleUrls: ['./competition-view.component.scss'],
})
export class CompetitionViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() weekDaysSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public dateRange: DateRange;
  private dateRangeRaw: { start: moment.Moment, end: moment.Moment };
  public weekDaysSelected: string[] = [];
  public weekDaysSelectedOld: string[] = [];
  public allVenues: Venue[] = [];
  public exportGroup: Venue[] = [];
  public competitionData;
  loadingData = true;

  constructor(private restaurantsService: RestaurantsService,
              private httpClient: HttpClient,
              @Inject('config') private config: NgStrapiAuthConfig) {}

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }

  selectionTrendsGroup($event) {
    console.log('selectionTrendsGroup', $event);
    console.log('this.venueData', this.venueData);
    this.exportGroup = [...$event.value];
  }

  async fetchCompetitionData() {
    try {
      this.loadingData = true;
      const results: any = await this.httpClient
          .post(this.config.apiUrl + '/venues/getCompetitionData', {data: {startDate: this.dateRangeRaw.start.format('YYYY-MM-DD'), endDate: this.dateRangeRaw.end.format('YYYY-MM-DD')}})
          .toPromise();
      console.log('fetchCompetitionData result', results);
      // this.availableAreas = [...results];
      this.competitionData = results;
      this.loadingData = false;
    } catch (e) {
      console.error('Error fetching Areas', e);
      return [];
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams } = change;
    this.weekDaysSelected = [...this.statisticsParams.weekDaysSelected];
    this.allVenues = [...this.restaurantsService.allVenuesData];

    if (statisticsParams && statisticsParams.firstChange) {
      // this.exportGroup.push(this.venueData);
      // this.exportGroup.push(...this.allVenues);
    }

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      const start = statisticsParams.currentValue.periodStartDate.clone();
      const end = statisticsParams.currentValue.periodEndDate.clone();

      this.dateRange = {
        start: start.format('DD/MM/YYYY'),
        end: end.format('DD/MM/YYYY'),
      };
      this.dateRangeRaw = {
        start: start,
        end: end,
      };
      this.fetchCompetitionData();
    }
  }
}
