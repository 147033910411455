<div class="login-register-box">
  <mat-card class="m-t-10">
    <mat-card-content>
      <span class="pop-tag" *ngIf="emailConfirmed"
        >Thanks, your email is now confirmed!</span
      >
      <form id="loginform">
        <div class="text-center">
          <img
            alt="homepage"
            src="assets/images/0.5x/full_color@0.5x.png"
            class="large-logo"
          />
          <h4 class="m-t-0">Welcome!</h4>
          <h5 *ngIf="showRedirectMessage">You have been successfully redirected to the new website. We had to update our URL to avoid issues with the website updates.
            You can continue using the same email and password as before.
            Apologies for any inconvenience!</h5>
        </div>

        <!-- Error Message Panel -->
        <div
          *ngIf="errorMessage"
          class="bg-danger p-10 text-white m-b-15"
          data-testid="error_message"
        >
          {{ errorMessage }}
        </div>

        <div fxLayout="row wrap">
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input
                matInput
                placeholder="Email"
                required=""
                #u1
                type="email"
                data-testid="email"
              />
            </mat-form-field>
          </div>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input
                matInput
                type="password"
                placeholder="Password"
                data-testid="password"
                required=""
                #p2
              />
            </mat-form-field>
          </div>
          <div fxFlex.gt-sm="50" fxFlex.gt-xs="50">
            <mat-checkbox color="warn" class="p-r-20" data-testid="remember"
              >Remember me</mat-checkbox
            >
          </div>
          <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">
            <a
              [routerLink]="['/authentication/forgot']"
              class="link"
              data-testid="forgot_password"
              >Forgot password?</a
            >
          </div>

          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <button
              *ngIf="!loginHappening"
              style="font-size: 16px"
              mat-raised-button
              color="primary"
              class="btn-block btn-lg m-t-20 m-b-20"
              (click)="check(u1.value, p2.value)"
              type="button"
              data-testid="sign_in"
            >
              Sign in
            </button>
            <button
              *ngIf="loginHappening"
              mat-raised-button
              color="primary"
              class="btn-block btn-lg m-t-20 m-b-20"
              type="button"
            >
              <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
