<mat-card class="pegasus-card embedded-card" #mainChartContainer id="mainChartContainer" [ngClass]="{'fixedHeight': isStandalone}">
    <mat-card-content #autoScroll *ngIf="autoScroll">
        <ng-container *ngTemplateOutlet="tableHolderTemplate;context:{tableData_1: playOffVenues, tableData_2: playOutVenues}" ></ng-container>
    </mat-card-content>
    <mat-card-content *ngIf="!autoScroll">
        <ng-container *ngTemplateOutlet="tableHolderTemplate;context:{tableData_1: playOffVenues, tableData_2: playOutVenues}"></ng-container>
    </mat-card-content>
</mat-card>


<ng-template #tableHolderTemplate let-tableData_1='tableData_1' let-tableData_2=''>
    <div
            fxLayout="row wrap"
            fxLayoutAlign="center center"
            *ngIf="loadingData"
    >
        <mat-spinner [diameter]="50" [strokeWidth]="8"></mat-spinner>
    </div>

    <ng-container *ngIf="tableData_1 && !tableData_2">
        <div
                *ngIf="!loadingData"
                fxLayout="column"
                [ngStyle]="{ 'justify-content': 'center'}"
        >
            <ng-container *ngTemplateOutlet="competitionTable;context:{dataSource: tableData_1, tableStyle: 'single'}"></ng-container>

        </div>
    </ng-container>

    <ng-container *ngIf="tableData_1 && tableData_2">
        <div
                *ngIf="!loadingData"
                fxLayout="column"
                [ngStyle]="{ 'justify-content': 'center'}"
        >
            <div fxLayout="row">
                <ng-container *ngTemplateOutlet="competitionTable;context:{dataSource: tableData_1, tableStyle: 'double', tableName: 'To Win'}" style="width: 100%"></ng-container>
                <ng-container *ngTemplateOutlet="competitionTable;context:{dataSource: tableData_2, tableStyle: 'double', tableName: 'No To Lose'}" style="width: 100%"></ng-container>
            </div>
        </div>
    </ng-container>




</ng-template>

<ng-template #competitionTable let-dataSource='dataSource' let-tableStyle='tableStyle' let-tableName='tableName'>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" [ngClass]="{'full-screen': isStandalone, 'single': tableStyle === 'single', 'double': tableStyle === 'double'}">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef > <ng-container *ngIf="!tableName"> Position </ng-container>  </th>
            <td mat-cell *matCellDef="let element"> {{element.position}}. </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> <ng-container *ngIf="!tableName"> Venue Name </ng-container> <ng-container *ngIf="tableName"> {{tableName}} </ng-container> </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef> <ng-container *ngIf="!tableName"> Score </ng-container> </th>
            <td mat-cell *matCellDef="let element"> {{element.score}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{
            'first': row.position == 1,
            'second': row.position == 2,
            'third': row.position == 3,
            'others': row.position > 3
            }"></tr>
    </table>
</ng-template>
