import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Input,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
// import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import { MenuItems } from '../../../shared/menu-items/menu-items';
import { NgStrapiAuthService } from '../../../services/strapi/auth/ng-strapi-auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class AppSidebarComponent implements OnDestroy {
  @Input() email: string;
  @Input() fullName: string;

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status = true;

  userType: string;

  itemSelect: number[] = [];
  parentIndex: Number;
  childIndex: Number;
  menuItems;
  brandLogo;
  currentYear = new Date().getFullYear();

  setClickedRow(i, j) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop(type?: string) {
    // console.log(type);
    if (type === 'info' || type === 'saperator') {
      return;
    }

    // console.log('Scroll to top');
    // console.trace();

    document.querySelector('.page-wrapper').scroll({
      top: 0,
      left: 0,
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItemsRaw: MenuItems,
    private authService: NgStrapiAuthService,
    public dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    console.log('this.authService.user', this.authService.user);
    const userData = this.authService.user;
    this.userType = userData.role.type;
    this.brandLogo = userData.brand.brandLogo;
    const menuItems = this.menuItemsRaw.getMenuitem();
    this.menuItems = menuItems.filter((item) => {
      console.log('item', item);
      if(item.children && item.children.length > 0) {
        item.children = item.children.filter((child) => {
          if(child.userGroups) {
            return child.userGroups.includes(this.userType) && userData.brand.availablePages.includes(child.state)
          }
          return true;
        });
      } else {
        if (item.userGroups) {
          return (
              item.userGroups.includes(this.userType) &&
              userData.brand.availablePages.includes(item.state)
          );
        }
      }

      return true;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  async callFunction(functionName: any) {
    if (functionName === 'logout') {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LeaveDialog);
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);

      if (result === 'logout') {
        this.authService.signOut();
      }
      // this.animal = result;
    });
  }

  assignTagId(idString: string) {
    return idString.replace(/\s/g, '-').toLowerCase();
  }
}

@Component({
  selector: 'app-leave-dialog',
  templateUrl: 'leave-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class LeaveDialog {
  constructor(public dialogRef: MatDialogRef<LeaveDialog>) {}

  cancel(): void {
    this.dialogRef.close();
  }

  logout() {
    this.dialogRef.close('logout');
  }
}
