<h1 mat-dialog-title>Are you sure you want to delete this user?</h1>
<div mat-dialog-content>
  <p>The user will lose access to the dashboard.</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No</button>
  <button
    data-testid="submit-button"
    mat-raised-button
    color="warn"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    Yes
  </button>
</div>
