<h3>Update area details</h3>
<div mat-dialog-content>
  <mat-form-field class="area-dialog--input">
    <input
      data-testid="name-input"
      matInput
      placeholder="Name"
      [(ngModel)]="data.formValues.name"
      [value]="data.formValues.name"
    />
  </mat-form-field>

  <mat-form-field class="area-dialog--input">
    <textarea
      data-testid="description-input"
      matInput
      placeholder="Description"
      [(ngModel)]="data.formValues.description"
      [value]="data.formValues.description"
    ></textarea>
  </mat-form-field>

  <!-- To be enabled once the types are defined by the client -->
  <!-- <mat-form-field>
    <mat-select
      placeholder="Type"
      [(ngModel)]="data.formValues.type"
      [value]="data.formValues.type"
    >
      <mat-option *ngFor="let type of data.areas" [value]="type.id"
        >{{type.name}}</mat-option
      >
    </mat-select>
  </mat-form-field> -->
</div>
<div mat-dialog-actions align="end">
  <button
    data-testid="cancel-button"
    mat-button
    mat-dialog-close
    mat-dialog-close="cancel"
  >
    Cancel
  </button>

  <button
    data-testid="submit-button"
    mat-button
    color="primary"
    [disabled]="!data.formValues.name"
    mat-dialog-close
    [mat-dialog-close]="data.formValues"
  >
    Save
  </button>
</div>
