import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RestaurantsService } from '../../../../services/restaurants.service';
import { StatisticsParams } from '../../../../utils/CommonInterfaces';
import { DateRangePickerSelection } from './custom-date-picker';

import * as moment from 'moment';

@Component({
  selector: 'app-compare-with',
  templateUrl: './compare-with.component.html',
  styleUrls: ['./compare-with.component.scss'],
})
export class CompareWithComponent implements OnChanges, OnDestroy {
  @Input() displayFields: string[] = [];
  @Input() statisticsParams: StatisticsParams;
  @Input() getPeriodType: (date: string) => string;

  @Output() selectedDateChange = new EventEmitter<{}>();

  @ViewChild('dateRangePicker') dateRangePicker: DateRangePickerSelection;

  private periodPrevVal: string;
  private periodSpecific: { start: moment.Moment; end: moment.Moment };

  public displayStations = false;
  public targetSingleDate: any;
  public date: moment.Moment;
  public compareWith: string;
  public timeScale: string;
  public primaryWeek: string;

  public weekDaysSelected: string[];
  public weekDaysSelectedOld: string[];

  public timeslotsSelected: string[];
  public timeslotsSelectedOld: string[];

  groupBySelected: string;

  public timeScaleValues: object[] = [
    { value: '20', name: '20 minutes', disabled: false },
    { value: '30', name: '30 minutes', disabled: false },
    { value: '60', name: '1 hour', disabled: false },
    { value: '240', name: '4 hour', disabled: false },
    { value: '1440', name: '1 day', disabled: false },
    { value: '10080', name: '1 week', disabled: false },
  ];

  public primaryWeekValues = [
    { value: 'current_week', name: 'Current week' },
    { value: 'last_week', name: 'Last week' },
    { value: 'specific_week', name: 'Specific week' },
  ];

  public weekDaysSelectorValues: object[] = [
    { value: '1', name: 'Monday' },
    { value: '2', name: 'Tuesday' },
    { value: '3', name: 'Wednesday' },
    { value: '4', name: 'Thursday' },
    { value: '5', name: 'Friday' },
    { value: '6', name: 'Saturday' },
    { value: '0', name: 'Sunday' },
  ];

  public timeslotSelectorValues: object[] = [
    { value: 'Lunch' },
    { value: 'Interpeak' },
    { value: 'Dinner' },
  ];

  groupBySelectorValues: object[] = [
    { value: 'day', name: 'Day' },
    { value: 'week', name: 'Week' },
  ];


  constructor(public restaurantsService: RestaurantsService) {
    this.periodSpecific = {
      start: moment().startOf('isoWeek'),
      end: moment().endOf('isoWeek'),
    };

    this.targetSingleDate = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
  }

  getName(value, array) {
    const objectData = array.find((elem) => elem.value === value);
    return objectData !== undefined ? objectData.name : 'Loading';
  }

  /**
   * Called when the datepicker value changes
   *
   * @param event
   */
  dateChanged(event) {
    this.date = moment(event).clone();

    // this.primaryWeek = this.getPeriodType(this.date.format('YYYY-MM-DD'));
  }

  weekChange() {
    if (this.primaryWeek === 'specific_week') {
      this.dateRangePicker.open();
    }
  }

  weekOpened() {
    this.periodSpecific = {
      start: moment(this.statisticsParams.selectedDate).startOf('isoWeek'),
      end: moment(this.statisticsParams.selectedDate).endOf('isoWeek'),
    };
  }

  selectWeekType(value) {
    if (value === 'specific_week') {
      this.dateRangePicker.open();
    }
  }

  specificWeekChange(action: string) {
    if (action === 'close') {
      if (!this.periodSpecific.start || !this.periodSpecific.end) {
        this.primaryWeek = this.periodPrevVal;
      }

      console.log('periodSpecific', this.periodSpecific);
    }
  }

  /**
   * selectionChange method for WEEKS DAYS SELECTOR
   */
  weekDaysChange() {
    // Validate the select box so at least one item is selected
    if (this.weekDaysSelected.length === 1) {
      this.weekDaysSelectedOld = [...this.weekDaysSelected];
    }
    if (this.weekDaysSelected.length === 0) {
      this.weekDaysSelected = [...this.weekDaysSelectedOld];
    }
  }

  /**
   * selectionChange method for GROUP TYPE SELECTOR
   */
  groupByChange() {

  }

  /**
   * selectionChange method for TIMESLOTS SELECTOR
   */
  timeslotsChange() {
    // Validate the select box so at least one item is selected
    if (this.timeslotsSelected.length === 1) {
      this.timeslotsSelectedOld = [...this.timeslotsSelected];
    }
    if (this.timeslotsSelected.length === 0) {
      this.timeslotsSelected = [...this.timeslotsSelectedOld];
    }
  }

  displayStationsChange($event: MatSlideToggleChange) {
    this.displayStations = $event.checked;
  }

  /**
   * Converts a date string to a date Momnetjs object
   *
   * @param date
   * @returns moment object
   */
  formatDateStringToMoment(date: string) {
    return moment(date, 'DD/MM/YYYY').toDate();
  }

  /**
   * Returns the start and end date of the specific week
   *
   * @param split if true, returns an array of start and end dates
   * @returns
   */
  specificWeekStartToEndDays(split: boolean = false): string | string[] {
    // const start = moment(
    //   this.periodSpecific.value.start || this.statisticsParams.periodStartDate
    // ).format('DD/MM/YYYY');
    // const end = moment(
    //   this.periodSpecific.value.end || this.statisticsParams.periodEndDate
    // ).format('DD/MM/YYYY');

    const start = moment(
      this.periodSpecific.start || this.statisticsParams.periodStartDate
    ).format('DD/MM/YYYY');
    const end = moment(
      this.periodSpecific.end || this.statisticsParams.periodEndDate
    ).format('DD/MM/YYYY');

    if (split) {
      return [start, end];
    }
    return `${start} - ${end}`;
  }

  /**
   * Used in custom date range picker to recieve the selected date range
   * @param $event
   */
  onReceiveDateRangeChange($event) {
    console.log('onReceiveDateRangeChange', $event);
    this.periodSpecific = { ...$event };
  }

  /**
   * Called from Apply button
   * Emit the selected date event to the parent statistics component
   *
   * When adding new properties, make sure to also add the new prop
   * to the parent component `onReceiveDateChange()` event properties
   */
  setDate() {
    let {
      selectedDate: newSelectedDate,
      periodType: newPeriodType,
      periodStartDate: newPeriodStartDate,
      periodEndDate: newPeriodEndDate,
    } = this.statisticsParams;
    const newDate = this.date;

    // Close sidebar compare-with component
    this.restaurantsService.toggleRightSidebar({ status: 'force-off' });

    if (this.displayFields.includes('singleDate')) {
      newPeriodStartDate = moment(newDate).startOf('isoWeek');
      newPeriodEndDate = moment(newDate).endOf('isoWeek');
      newSelectedDate = newDate;
      newPeriodType = this.getPeriodType(newDate.format('YYYY-MM-DD'));

      console.log('this.primaryWeek for singleDate', newPeriodType);
    } else {
      newPeriodType = this.primaryWeek;

      console.log('this.primaryWeek', newPeriodType);
      switch (newPeriodType) {
        case 'specific_week':
          newPeriodStartDate = this.periodSpecific.start;
          newPeriodEndDate = this.periodSpecific.end;
          newSelectedDate = newPeriodStartDate;
          break;

        case 'last_week':
          newPeriodStartDate = moment()
              .subtract(1, 'weeks')
              .startOf('isoWeek');
          newPeriodEndDate = moment()
              .subtract(1, 'weeks')
              .endOf('isoWeek');
          newSelectedDate = newPeriodStartDate;
          break;

        case 'current_week':
          newPeriodStartDate = moment().startOf('isoWeek');
          newPeriodEndDate = moment().endOf('isoWeek');
          newSelectedDate = moment();
          break;

        default: // 'current_week'
          newPeriodStartDate = moment(newDate).startOf('isoWeek');
          newPeriodEndDate = moment(newDate).endOf('isoWeek');
          newSelectedDate = newDate;
          break;
      }
    }

    const statisticsParamsToEmit = {
      // selectedDate: newDate,
      selectedDate: newSelectedDate,
      periodType: newPeriodType,
      periodStartDate: newPeriodStartDate,
      periodEndDate: newPeriodEndDate,
      weekDaysSelected: this.weekDaysSelected,
      timeslotsSelected: this.timeslotsSelected,
      groupBy: this.groupBySelected,
    };


    this.selectedDateChange.emit(statisticsParamsToEmit);
  }

  ngOnChanges(changes: SimpleChanges): void {

    const { statisticsParams, displayFields } = changes;

    // Due to the component being destroyed when switching between daily and weekly
    // the selectedDate resets
    if (statisticsParams && statisticsParams.firstChange) {
      this.primaryWeek = this.statisticsParams.periodType;
      this.weekOpened();
    }

    if (statisticsParams) {
      this.date = this.statisticsParams.selectedDate.clone();
      this.groupBySelected = this.statisticsParams.groupBy;
      this.weekDaysSelected = this.statisticsParams.weekDaysSelected;
      this.timeslotsSelected = this.statisticsParams.timeslotsSelected;
    }
  }

  ngOnDestroy(): void {
    // this.paramsSubscription.unsubscribe();
    // this.localChartParamsSubscription.unsubscribe();
    // this.latestDataDateSubscription.unsubscribe();
  }


}
