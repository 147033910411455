<h1 mat-dialog-title id="logout-dialog-title">
  Are you sure you want to log out?
</h1>
<!--<div mat-dialog-content>This dialog showcases the title, close, content and actions elements.</div>-->
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <span class="p-b-20">
    <button
      id="logout-dialog-cancel-btn"
      color="accent"
      mat-button
      (click)="cancel()"
    >
      No, cancel
    </button>
  </span>

  <span class="p-b-20">
    <button
      id="logout-dialog-submit-btn"
      color="warn"
      mat-button
      (click)="logout()"
    >
      Yes, log me out
    </button>
  </span>
</div>
