  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
          <div class="text-center">
            <img alt="homepage" src="assets/images/0.5x/full_color@0.5x.png" class="large-logo">
            <h4 class="m-t-0">Almost there!</h4>
          </div>
          <p class="text-center font-14">Please check your inbox to confirm your email.</p>

          <div fxLayout="column" fxLayoutAlign="space-around">

          </div>

      </mat-card-content>
    </mat-card>
  </div>
