  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/0.5x/full_color@0.5x.png" class="large-logo">
            <h4 class="m-t-0">Registration</h4>
            <div *ngIf="msg" class="bg-danger p-10 text-white m-b-15">{{ msg }}</div>
          </div>
          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Your email" type="email" [formControl]="form.controls['email']">
              </mat-form-field>
              <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="text-danger support-text">Please enter a valid email.</small>
              <small *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched" class="text-danger support-text">Please enter a valid email.</small>
            </div>

            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Your name" type="string" [formControl]="form.controls['full_name']">
              </mat-form-field>
              <small *ngIf="form.controls['full_name'].hasError('required') && form.controls['full_name'].touched" class="text-danger support-text">Please enter your name.</small>
              <small *ngIf="form.controls['full_name'].errors?.email && form.controls['full_name'].touched" class="text-danger support-text">Please enter your name.</small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput type="password" placeholder="Password" [formControl]="form.controls['password']">
              </mat-form-field>
              <small *ngIf="form.controls['password'].hasError('minlength') && form.controls['password'].touched" class="text-danger support-text">Minimum length is 8 symbols.</small>
              <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger support-text">Please enter a password.</small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Confirm password" [formControl]="form.controls['confirmPassword']" type="password">
              </mat-form-field>
              <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" class="text-danger support-text">Please enter a password.</small>
              <small *ngIf="form.controls['confirmPassword'].errors?.equalTo" class="text-danger support-text">Password don't match.</small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-checkbox  matInput class="font-14" [formControl]="form.controls['agreeToRules']" style="white-space: break-spaces !important;">I agree with
                <a href="#"  target="_blank" class="link text-info">terms and conditions</a>.</mat-checkbox>
              <small *ngIf="form.controls['agreeToRules'].hasError('required') && form.controls['agreeToRules'].touched" class="text-danger support-text">Please accept our terms and conditions.</small>
            </div>
            <button *ngIf="!loading" mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit" [disabled]="!form.valid">Sign up</button>
            <button *ngIf="loading" mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" [disabled]="!form.valid"><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button>

            <div class="text-center" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <span>Already have an account?
                <a [routerLink]="['/login']" class="link text-info"> Login</a>
              </span>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

