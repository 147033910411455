import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl, ValidationErrors, AbstractControl
} from '@angular/forms';
import {NgStrapiAuthService} from '../../services/strapi/auth/ng-strapi-auth.service';

// const password = new FormControl('', Validators.required);



// const confirmPassword = new FormControl('');


@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {
  public form: FormGroup;
  private code: string;
  public msg;
  loading: boolean = false;


  password = new FormControl('', Validators.compose([Validators.minLength(8), Validators.required]));
  confirmPassword = new FormControl('', this.matchValues('password'));

  constructor(private fb: FormBuilder, private router: Router,
              private authService: NgStrapiAuthService, private route: ActivatedRoute) {}

  ngOnInit() {

    this.code = this.route.snapshot.queryParamMap.get('code');
    if(this.code === null){
      return this.router.navigate(['login']);
    }

    this.form = this.fb.group({
      password: this.password,
      confirmPassword: this.confirmPassword,
    });

    this.form .controls.password.valueChanges.subscribe(() => {
      this.form .controls.confirmPassword.updateValueAndValidity();
    });
  }

  async onSubmit() {
    this.loading = true;
    try{
      let changeResult = await this.authService.changePassword(this.code, this.form.value.password)
      if(changeResult.jwt !== undefined){
        this.router.navigate(['dashboard']);
        this.loading = false;
      }
    }catch (e) {
      const errorMsg = e[0].messages[0].message;
      this.loading = false;
      // if(errorMsg === 'Incorrect code provided.'){
      //   return this.msg = 'Неверная ссылка для изменения пароля';
      // }
      return this.msg = errorMsg;
    }
    // this.router.navigate(['/authentication/login']);
  }

  private  matchValues(
      matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
          ? null
          : { isMatching: false };
    };
  }
}
