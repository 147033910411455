import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {NgStrapiAuthService} from '../../services/strapi/auth/ng-strapi-auth.service';


const password = new FormControl('', Validators.compose([Validators.minLength(8), Validators.required]));
// , CustomValidators.equalTo(password)
const confirmPassword = new FormControl('');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  public msg: string;
  loading = false;
  constructor(private fb: FormBuilder, private router: Router,
              private authService: NgStrapiAuthService) {}

  ngOnInit() {
    try {
      const status = this.authService.autoSignIn();
      console.log('status', status);
      this.router.navigate(['dashboard']);
    } catch (e) {
      console.log('e', e);
    }

    this.form = this.fb.group({
      email: [
        null,
        // , CustomValidators.email
        Validators.compose([Validators.required])
      ],
      full_name: [
        null,
        Validators.compose([Validators.required])
      ],
      password: password,
      confirmPassword: confirmPassword,
      agreeToRules: [
          false,
        Validators.pattern('true')
      ]
    });
  }

  async register(full_name, email, passwordData) {
    this.loading =  true;
    try {
      const user = await this.authService.register(full_name, email, passwordData);
      console.log('user', user);
      if (user === true) {
        this.router.navigate(['authentication/confirm-email']);
        this.loading =  false;
      }
      // else {
      //     this.msg = 'Invalid Username or Password';
      // }
    } catch (e) {
      console.log('e', e);
      this.loading =  false;
      // if(e[0].messages[0].message === 'Email is already taken.'){
      //   return this.msg = 'Email уже зарегистрирован';
      // }
      this.msg = e[0].messages[0].message;
    }


  }

  onSubmit() {
    this.register(this.form.value.full_name, this.form.value.email, this.form.value.password);
    // this.router.navigate(['/']);
  }
}
