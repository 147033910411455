    <div *ngIf="loaded">
        <mat-card class="privacy-card">
            <mat-card-content>
                <div class="text-center">
                    <img alt="homepage" src="assets/images/0.5x/full_color@0.5x.png" style="height: 30px">
                    <h4 class="m-t-0">Договор публичной оферты</h4>
                </div>
                <p class="text-left font-14" style="white-space: pre-line;" *ngIf="loaded">{{ofertText}}</p>

                <div fxLayout="column" fxLayoutAlign="space-around">

                </div>

            </mat-card-content>
        </mat-card>
    </div>
