import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Area,
  DeleteDialogData,
  InviteDialogData,
  User,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { MatSelectChange } from '@angular/material/select';
import { NgStrapiAuthConfig } from '../../../../services/strapi/auth/ng-strapi-auth-config';

@Component({
  selector: 'app-users-settings',
  templateUrl: './users-settings.component.html',
  styleUrls: ['./users-settings.component.scss'],
})
export class UsersSettingsComponent implements OnInit {
  @Input() users: User[] = [];
  @Input() fetchUsers: any = {};
  @Input() brandVenues: Venue[] = [];
  @Input() brandVenuesRaw: Venue[] = [];
  @Input() brandAreas: Area[] = [];
  @Input() brandAreasRaw: Area[] = [];

  public roles = [
    { name: 'Brand Manager', type: 'brand_admin' },
    { name: 'Venue Manager', type: 'venue_manager' },
    { name: 'Area Manager', type: 'area_manager' },
  ];
  public displayedColumns: string[] = [
    'username',
    'email',
    'lastAccessed',
    'active',
    'role',
    'areaAccess',
    'venuesAccess',
    // 'viewOnly',
    'actions',
  ];

  private apiUrl: string;

  constructor(
    public dialog: MatDialog,

    private httpClient: HttpClient,
    private snackBar: MatSnackBar,

    @Inject('config') private config: NgStrapiAuthConfig
  ) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.UsersSettingsComponent = this;
    }

    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
  }

  public inviteUser(): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      // width: '250px',
      data: {
        username: undefined,
        email: undefined,
        role: undefined,
        roles: this.roles,
        brandAreas: this.brandAreas,
        brandVenues: this.brandVenues,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        delete result.brandAreas;
        delete result.brandVenues;
        delete result.roles;
        try {
          const res: any = await this.httpClient
            .post(this.apiUrl + '/auth/local/register', {
              ...result,
              invitedUser: true,
            })
            .toPromise();
          console.log('res', res);
          this.openSnackBar('User invited', 'Ok');
        } catch (e) {
          console.log('e', e);
          this.openSnackBar(e[0].messages[0].message, 'Ok');
        }

        await this.fetchUsers();
      }
    });
  }

  public deleteUser(username, userId): void {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      // width: '250px',
      data: { username: username, userId },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.openSnackBar('User deleted', 'Ok');
        try {
          const res: any = await this.httpClient
            .post(this.apiUrl + '/dasboard-settings/deleteUser', {
              id: result.userId,
            })
            .toPromise();
          console.log('res', res);
          this.openSnackBar('User deleted', 'Ok');
        } catch (e) {
          console.log('e', e);
          this.openSnackBar(e[0].messages[0].message, 'Ok');
        }

        await this.fetchUsers();
      }
    });
  }

  public async changeUserAccess($event: MatSelectChange, user): Promise<void> {
    switch ($event.source.ngControl.name) {
      case 'role':
        if ($event.value === 'brand_admin') {
          user.venuesAccess = 'All';
          user.areaAccess = 'all';
        }
        if ($event.value === 'area_manager') {
          user.venuesAccess = 'All';
          user.areaAccess = this.brandAreasRaw[0].id;
        }
        if ($event.value === 'venue_manager') {
          user.venuesAccess = this.brandVenuesRaw[0].name;
          user.areaAccess = this.brandVenuesRaw[0].area
            ? this.brandAreasRaw.find(
                (areaData) => areaData.id === this.brandVenuesRaw[0].area.id
              ).id
            : 'no_area';
        }
        break;
      case 'area':
        break;
      case 'venue':
        const venue = this.brandVenuesRaw.find(
          (venueData) => venueData.name === $event.value
        );
        user.areaAccess = venue.area ? venue.area?.id : 'no_area';
        break;
    }

    const payload = {
      user,
    };

    try {
      await this.httpClient
        .post(this.apiUrl + '/dasboard-settings/changeDefaultVenue', payload)
        .toPromise();
      this.openSnackBar('User updated', 'Ok');
    } catch (e) {
      console.log('e', e);
      try {
        this.openSnackBar(e, 'Ok');
      } catch (e) {}
    }
    // make a call and call again to fetch users
    await this.fetchUsers();
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3 * 1000,
    });
  }

  ngOnInit(): void {}
}

// -----------------------------------------------------
// INVITE & DELETE USER DIALOGS
// -----------------------------------------------------

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: 'invite-user-dialog.html',
})
export class InviteUserDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InviteDialogData
  ) {
    console.log('data', data);
    data.brandAreas = data.brandAreas.filter((area) => {
      // check if any venues have this area
      const venues = data.brandVenues.filter((venue) => {
        return venue.area ? venue.area.id === area.id : false;
      });
      return venues.length > 0;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkInputsCorrect() {
    switch (this.data.role) {
      case 'brand_admin':
        return this.data.username && this.data.email;
      case 'venue_manager':
        return this.data.username && this.data.email && this.data.venue;
      case 'area_manager':
        return this.data.username && this.data.email && this.data.area;
      default:
        return false;
    }
  }

  changingRole() {
    console.log('changingRole', this.data);
  }
}

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: 'delete-user-dialog.html',
})
export class DeleteUserDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
