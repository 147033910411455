import { Component, OnInit } from '@angular/core';
import { NgStrapiAuthService } from '../../services/strapi/auth/ng-strapi-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements OnInit {
  constructor(
    private authService: NgStrapiAuthService,
    private routes: Router
  ) {}

  ngOnInit(): void {
    const userData = this.authService.user;
    console.log('userData', userData);
    if (userData.role.type === 'live_venue_viewer') {
      this.routes.navigate(['live-view']);
    } else {
      console.log('not a live_venue_viewer', userData);

      if (!userData.brand) {
        console.error('User does not have a brand assigned');
        // TODO:
        // - display error message
      }

      this.routes.navigate([userData.brand.availablePages[0]]);
    }
  }
}
