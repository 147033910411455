import { Component } from '@angular/core';
import { ConsoleToggleService } from './services/console-toggle.service';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    // returns installed service workers
    if (registrations.length) {
      for (const registration of registrations) {
        registration.unregister();
      }
    }
  });
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {
  constructor(private consoleToggleService: ConsoleToggleService) {
    this.consoleToggleService.disableConsoleInProduction();
  }

}
