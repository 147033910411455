<!--<h1 mat-dialog-title>Hi</h1>-->
<div mat-dialog-content>
  <p>Please fill the form to invite new user</p>

  <mat-form-field appearance="fill">
    <mat-label>Name and Surname</mat-label>
    <input
      data-testid="name-input"
      matInput
      [(ngModel)]="data.username"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input
      data-testid="email-input"
      matInput
      [(ngModel)]="data.email"
      pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
      required
    />
  </mat-form-field>
  <!--	<mat-form-field appearance="fill">-->
  <!--		<mat-label>Venues user can access</mat-label>-->
  <!--		<mat-select [(ngModel)]="data.venuesAccess" name="venue" (valueChange)="changingData()">-->
  <!--			<mat-option *ngFor="let venue of data.brandVenues" [value]="venue.id" [ngStyle]="{'background': venue.name === 'All' ? 'rgba(0,255,0,0.1)' : ''}">-->
  <!--				{{venue.name}}-->
  <!--			</mat-option>-->
  <!--		</mat-select>-->
  <!--	</mat-form-field>-->

  <mat-form-field appearance="fill">
    <mat-label>Role</mat-label>
    <mat-select
      data-testid="role-select"
      [(ngModel)]="data.role"
      name="role"
      (selectionChange)="changingRole()"
    >
      <mat-option *ngFor="let role of data.roles" [value]="role.type">
        {{role.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="data.role === 'area_manager'">
    <mat-label>Area</mat-label>
    <mat-select data-testid="area-select" [(ngModel)]="data.area" name="role">
      <mat-option *ngFor="let role of data.brandAreas" [value]="role.id">
        {{role.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="data.role === 'venue_manager'">
    <mat-label>Venue</mat-label>
    <mat-select data-testid="venue-select" [(ngModel)]="data.venue" name="role">
      <mat-option *ngFor="let role of data.brandVenues" [value]="role.id">
        {{role.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--	<mat-form-field appearance="fill">-->
  <!--	</mat-form-field>-->
</div>
<div mat-dialog-actions>
  <button
    data-testid="invite-form-cancel-button"
    mat-button
    (click)="onNoClick()"
  >
    Cancel
  </button>
  <button
    data-testid="invite-form-submit-button"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="data"
    cdkFocusInitial
    [disabled]="!checkInputsCorrect()"
  >
    Send Invite
  </button>
</div>
