import { Component, OnInit } from '@angular/core';
import {RestaurantsService} from '../../services/restaurants.service';
import {Subscription} from 'rxjs';
import {Venue} from '../../utils/CommonInterfaces';

@Component({
  selector: 'app-live-view',
  templateUrl: './live-view.component.html',
  styleUrls: ['./live-view.component.scss']
})
export class LiveViewComponent implements OnInit {
  private restaurantServiceSubscription: Subscription;
  public venueData: Venue;
  public venuesList: Venue[];

  constructor(private restaurantsService: RestaurantsService) { }

  ngOnInit(): void {
    this.restaurantServiceSubscription =
        this.restaurantsService.selectedVenueDataSubject.subscribe(
            (venueData) => {
              if (!venueData) { return; }
              this.venueData = venueData;
              this.venuesList = [...this.restaurantsService.allVenuesData];
              console.log('this.venuesList', this.venuesList);
            }
        );

  }

}
