import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgStrapiAuthConfig } from '../../services/strapi/auth/ng-strapi-auth-config';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Area, User, Venue } from '../../utils/CommonInterfaces';
import { sortByName } from '../../utils/utils';
import { RestaurantsService } from '../../services/restaurants.service';

@Component({
  selector: 'app-user',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  public basicInfoLoaded = false;
  public loaded = false;
  public errors: any;
  public isAdminUser = false;
  public users: User[] = [];
  public userStatus: any = {
    dataStatus: 'loading', // loading, loaded
  };

  public brandVenues: Venue[];
  public brandVenuesRaw: Venue[];
  public brandAreas: Area[];
  public brandAreasRaw: Area[];

  private apiUrl: string;
  private brandVenueQuerySubscription: Subscription;
  private meQuerySubscription: Subscription;

  constructor(
    public dialog: MatDialog,

    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private restaurantsService: RestaurantsService,

    @Inject('config') private config: NgStrapiAuthConfig
  ) {
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.SettingsComponent = this;
    }

    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
  }

  public async fetchUsers(): Promise<void> {
    const res: any = await this.httpClient
      .post(this.apiUrl + '/dasboard-settings/getBrandUsers', {})
      .toPromise();

    const users = res.map((userData) => {
      let areaAccess = '';

      switch (userData.role.type) {
        case 'brand_admin':
          areaAccess = 'all';
          break;
        case 'venue_manager':
          areaAccess = userData.dasboard_setting.defaultVenue.area
            ? String(userData.dasboard_setting.defaultVenue?.area.id)
            : 'no_area';
          break;
        case 'area_manager':
          areaAccess = userData.area.id.toString();
          break;
        default:
          areaAccess = 'no_area';
          console.log(
            'no area access default switch value, most set proper behaviour'
          );
          break;
      }

      return {
        username: userData.username,
        email: userData.email,
        lastAccessed: userData.lastAccessed ? userData.lastAccessed : 'Never',
        venuesAccess:
          userData.role.type === 'venue_manager'
            ? userData.dasboard_setting.defaultVenue.name
            : 'All',
        areaAccess: areaAccess,
        active: userData.active,
        id: userData.id,
        role: userData.role.type,
        isAdmin: userData.role.type === 'brand_admin',
        viewOnly: userData.role.type === 'live_venue_viewer',
      };
    });

    this.users = users.sort((a, b) => a.email.localeCompare(b.email));
  }

  public async loadBrandVenueSettings(): Promise<void> {
    const data: any = await this.httpClient
      .post(this.apiUrl + '/brands/getBrandVenues', {})
      .toPromise();
    // receivedData = true;
    if (data.venues) {
      data.venues = data.venues.sort(sortByName);

      const tempVenues = data.venues.map((venue) => {
        if (!venue.chartsSettings) {
          venue.chartsSettings = data.self.brand.defaultChartsSettings;
        }
        return venue;
      });
      this.brandVenuesRaw = JSON.parse(JSON.stringify(tempVenues));
      // data.venues.push({ name: 'All', id: 'brand_admin' });
      this.brandVenues = data.venues;

      this.brandAreasRaw = JSON.parse(JSON.stringify(data.self.brand.areas));
      // data.self.brand.areas.push({ name: 'All', id: 'all' });
      // data.self.brand.areas.push({ name: 'Not in area', id: 'no_area' });
      this.brandAreas = data.self.brand.areas;
    }
    await this.fetchUsers();
    // this.brandVenueQuerySubscription.unsubscribe();
    // this.loading.brandVenues = loading;

    // this.restaurantsService.refetchBrandVenues();
  }

  public refetchBrandSettings(): void {
    console.log('settings-component: refetchBrandSettings()');
    // this.loadBrandVenueSettings();
    // this.brandVenueQuery.refetch();
    this.loadBrandVenueSettings();
    this.restaurantsService.refetchBrandVenues();
  }

  public async viewOnlyChange(event, id): Promise<void> {
    console.log('viewOnlyChange', event, id);
    try {
      const res: any = await this.httpClient
        .post(this.apiUrl + '/dasboard-settings/toggleViewOnlyAccess', {
          id: id,
        })
        .toPromise();
      console.log('res', res);
      this.openSnackBar('View only changed', 'Ok');
    } catch (e) {
      console.log('e', e);
      this.openSnackBar(e[0].messages[0].message, 'Ok');
    }
    await this.fetchUsers();
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3 * 1000,
    });
  }

  private async loadUserAndBrandSettings(): Promise<void> {
    const res: any = await this.httpClient
      .get(this.apiUrl + '/users/me', {})
      .toPromise();
    const data = { accountData: res, dataStatus: 'loaded' };

    this.userStatus = data;
    this.isAdminUser = data.accountData.adminUser;

    console.group('loadUserAndBrandSettings()');
    console.log('res', res);
    console.log('data', data);
    console.groupEnd();

    if (this.isAdminUser) {
      await this.loadBrandVenueSettings();
    }

    this.loaded = true;
  }

  ngOnInit() {
    this.loadUserAndBrandSettings();
  }

  ngOnDestroy() {
    this.brandVenueQuerySubscription?.unsubscribe();
    this.meQuerySubscription?.unsubscribe();
  }
}
