import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { NgStrapiAuthService } from './ng-strapi-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: NgStrapiAuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            this.authenticationService.autoSignIn();
        } catch (authErr) {
            console.error('error on auto sign in: ', authErr);
        }

        const currentUser = this.authenticationService.authenticated;
        if (currentUser) {
            const userData = this.authenticationService.user;
            // console.log('userData', userData)
            // if(userData.role.name === 'Authenticated') {
            return true;
            // }
            // else {
            //     this.router.navigate(['/trainer']);
            //     return false;
            // }
            // logged in so return true
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
